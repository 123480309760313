<template>
  <div>
    <Gallery
      class="search-one--gallery"
      :gallery="property.gallery"/>

    <section class="section is-small">
      <div class="container is-desktop">
        <PpBreadcrumb
          class="is-size-7-mobile search-one--breadcrumb"
          :routes="breadCrumb"/>
        <SearchOneSummaryMobile
          :property="property"
          :reviews="lv2State.searchOne.reviews" />
      </div>
    </section>

    <section class="section is-small">
      <div class="container is-desktop is-clearfix">
        <div class="search-one--side">
          <div class="info-block">
            <h4 class="is-size-6 is-uppercase has-text-info" slot="prepend">Amenities</h4>
            <ul class="columns is-mobile is-multiline is-gapless" style="font-size: 14px;">
            <AmenitiesLabel
              class="column is-6"
              v-for="it in property.amenities"
              :key="it.key"
              v-bind="it"
              />
            </ul>
          </div>
          <div class="info-block is-hidden-mobile">
            <h4 class="is-size-6 is-uppercase has-text-info">Description</h4>
            <div class="content is-small" v-html="property.description"></div>
          </div>
        </div>
        <div class="search-one--list">
          <div class="has-background-white-ter" style="padding: 12px 0;">
            <SearchBarOne @search="handleSearch" id="pckgslist">
              <h4 class="is-size-6 is-uppercase has-text-info" slot="prepend">Availability</h4>
            </SearchBarOne>
          </div>
          <PackagesList
            v-if="availability.search || mx_searchOne_isLoading"
            :searchId="query.searchId"
            :propertyCode="query.propertyCode"
            :isLoading="mx_searchOne_isLoading"
            :selectedPckgIdx="selectedPckgIdx"
            :packages="availability.packages">
            <component
              slot-scope="{ pckg, idx }"
              :is="componentPackage"
              :pckg="pckg">
              <el-button
                slot="call-to-action"
                type="warning"
                @click="handleSelectedPackage(pckg, idx)"
                size="small">Select</el-button>
            </component>
          </PackagesList>
          <EmptyState
            v-else
            class="has-text-centered"
            :isLoading="false"
            message="Select your check-in/check-out dates to check the avialability."
          />
        </div>
      </div>
    </section>
    <div class="section is-small">
      <div class="container is-desktop">
        <div class="info-block">
            <h4 class="is-size-6 is-uppercase has-text-info">Property Policies</h4>
            <div class="property-block columns is-gapless is-multiline">
              <div class="column is-3">
                <div class="list is-small wrap is-gapless">
                  <div class="list-content list-fullwidth">
                    <div class="list-label">Check-in time</div>
                    <div>{{ property.checkInTime || '–' }}</div>
                  </div>
                  <div class="list-content list-fullwidth">
                    <div class="list-label">Check-out time</div>
                    <div>{{ property.checkOutTime || '–' }}</div>
                  </div>
                  <!-- <div class="list-content list-fullwidth">
                    <div class="list-label">Payment type</div>
                    <div>{{ property.payments || '–' }}</div>
                  </div> -->
                  <div class="list-content list-fullwidth">
                    <div class="list-label">Nearest airport</div>
                    <div>{{ property.airportCode || '–' }}</div>
                  </div>
                </div>
              </div>
              <div class="column is-9">
                <div class="list is-gapless is-narrow">
                  <div class="list-content">
                    <div class="content is-small" v-html="property.policy"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="info-block is-hidden-tablet">
            <h4 class="is-size-6 is-uppercase has-text-info">Description</h4>
            <div class="content is-small" v-html="property.description"></div>
          </div>
      </div>
    </div>
    <BookingPolicyPopup
      v-if="bookingPolicyModalVisibility"
      :property="property"
      :selectedPckg="selectedPckg"
      :searchId="query.searchId"
      :currency="query.currency"
      :searchObject="availability.search"
      @close="toggleBookingPolicyModal"
      @next="handleReserveNow"
      />
  </div>
</template>

<script>
import Gallery from '../components/Gallery'
import SearchOneSummaryMobile from './SearchOneSummaryMobile'
import SearchBarOne from '../components/SearchBarOne'
import BookingPolicyPopup from '../components/BookingPolicyPopup'
import EventBus from '../EventBus'
import PackagesList from '../components/PackagesList'
import AmenitiesLabel from '../components/AmenitiesLabel'
import searchOneMix from '../mixins/searchOne'
import smoothScroll from '../plugins/smoothScroll'

export default {
  name: 'SearchOne',
  mixins: [ searchOneMix ],
  components: {
    [Gallery.name]: Gallery,
    [BookingPolicyPopup.name]: BookingPolicyPopup,
    [SearchOneSummaryMobile.name]: SearchOneSummaryMobile,
    [SearchBarOne.name]: SearchBarOne,
    [PackagesList.name]: PackagesList,
    [AmenitiesLabel.name]: AmenitiesLabel,
    'PackagesMobile': () => import(/* webpackChunkName: "mobile" */ '../components/PackagesMobile'),
    'Packages': () => import(/* webpackChunkName: "tablet" */ '../components/Packages')
  },
  props: {
    params: Object,
    query: Object
  },
  data () {
    return {
      selectedPckgIdx: 0,
      selectedPckg: {},
      fetchCompleted: false,
      bookingPolicyModalVisibility: false,
      /** overwrite mixin data */
      fetchInterval: 873 // ms
    }
  },
  computed: {
    componentPackage () {
      return this.$mq.mobile ? 'PackagesMobile' : 'Packages'
    },
    history () {
      return window.history
    },
    property () {
      return this.lv2State.searchOne.property || { name: this.params.name }
    },
    availability () {
      return this.lv2State.searchOne.availability || {}
    },
    breadCrumb () {
      return [
        {
          label: this.query.locationQuery,
          replace: true,
          historyBack: true,
          route: { name: 'searchMany', params: { locationQuery: this.query.locationQuery }, query: this.query }
        }
      ]
    }
  },
  created () {
    this.asyncData()
  },
  beforeRouteEnter (to, from, next) {
    if (['searchMany', 'searchMap', 'searchMapPreview', 'searchManyPreview'].includes(from.name)) {
      return next(vm => {
        vm.asyncData()
      })
    }
    next()
  },
  activated () {
    EventBus.$on('currency-changed', ({ currency } = {}) => {
      this.$router.replace({ name: 'property', query: { ...this.query, currency }, params: this.params }, () => this.asyncData())
    })
  },
  deactivated () {
    EventBus.$off('currency-changed')
  },
  methods: {
    toggleBookingPolicyModal () {
      this.bookingPolicyModalVisibility = !this.bookingPolicyModalVisibility
      if (this.bookingPolicyModalVisibility === false) this.selectedPckg = {}
    },
    asyncData () {
      this.mx_searchOne_cancelFetch()
      this.mx_searchOne_fetch()
      this.$lv2Store.setSearchMany({ selected: this.query.propertyCode })
    },
    handleSearch (val) {
      const newQuery = { ...this.query, ...val }
      const { regionName, ...query } = newQuery

      this.$router.replace(
        { name: 'property', params: { name: this.property.name, propertyCode: this.params.propertyCode }, query },
        () => {
          this.$lv2Store.setSearchOne({ isSearching: true, availability: { results: [] } })
          smoothScroll.scrollTo('#pckgslist')
          this.mx_searchOne_cancelFetch()
          this.mx_searchOne_fetch()
        }
      )
    },
    async handleSelectedPackage (pckg, idx) {
      this.selectedPckgIdx = idx
      this.selectedPckg = pckg
      setTimeout(() => {
        this.toggleBookingPolicyModal()
      }, 380)
    },
    handleReserveNow (nextStep) {
      this.$router.push(
        { name: 'checkoutForm', params: { ckToken: nextStep.ckToken }, query: { currency: this.query.currency } },
        () => (this.bookingPolicyModalVisibility = false)
      )
    }
  },
  metaInfo () {
    return {
      title: `Property: ${this.property.name} – ${this.query.locationQuery}`,
      titleTemplate: (title) => title
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../styles/bulma-variables';
  .search-one--gallery {
    height: 250px;
  }
  .search-one--breadcrumb /deep/ #pp-icon-arrow-right {
    fill: $grey-light;
  }
  @include tablet {
    .search-one--list {
      margin-right: 350px;
    }
    .search-one--gallery {
      height: 300px;
    }
    .search-one--side {
      float: right;
      padding: $bleed * 2 0;
      width: 310px;
    }
  }
  .info-block {
    // padding: $bleed * 2;
    margin-bottom: $bleed * 3;
  }
  .list-fullwidth {
    margin-bottom: $bleed * 2;
  }
  .list-label {
    font-weight: bold;
  }
</style>
