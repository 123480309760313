<template>
  <el-popover
    v-model="calanderVisibility"
    popper-class="daterange--wrapper"
    :popper-append-to-body="true"
    transition="view-fade-slow"
    trigger="focus"
    @show="handleShowed">
    <el-input
    slot="reference"
    prefix-icon="el-icon-date"
    placeholder="When?"
    :readonly="true"
    :value="displayStr">
      <i
        id="date-range-can"
        class="el-input__icon"
        style="margin-right: 6px;"
        slot="suffix">{{ nights | nightLabel }}</i>
    </el-input>
  <div>
  <AirbnbStyleDatepicker
    :inline="true"
    :trigger-element-id="'date-range-can'"
    :offset-y="0"
    :offset-x="0"
    :minDate="minDate"
    :endDate="maxDate"
    :fullscreen-mobile="true"
    :date-one="value.checkInDate"
    :date-two="value.checkOutDate"
    :showShortcutsMenuTrigger="false"
    :showActionButtons="showActionButtons"
    @date-one-selected="selectedDateOne"
    @date-two-selected="selectedDateTwo"
    @apply="handleApply"
    @cancelled="handleCancelled"
    @closed="handleClosed"
    @opened="handleOpened"
  >
  <i slot="close-icon"
    class="el-icon-error is-size-5"
    @click="handleClosed"/>
  </AirbnbStyleDatepicker>
  </div>
  </el-popover>
</template>

<script>
const dateFormat = {
  timeZone: 'UTC',
  month: 'short',
  day: '2-digit'
}
export default {
  name: 'DateRange',
  props: {
    value: {
      type: Object,
      required: true
    },
    layoutMode: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      calanderVisibility: false,
      dateOne: this.value.checkInDate,
      dateTwo: this.value.checkOutDate,
      minDate: '',
      maxDate: ''
    }
  },
  computed: {
    nights () {
      return this.$root.$options.filters.countDays(this.value.checkInDate, { endDate: this.value.checkOutDate })
    },
    displayStr () {
      if (!this.value.checkInDate) {
        return ''
      }

      const checkIn = this.$root.$options.filters.date(this.value.checkInDate, dateFormat)
      const checkOut = this.$root.$options.filters.date(this.value.checkOutDate, dateFormat)
      return `${checkIn}  –  ${checkOut}`
    },
    showActionButtons () {
      return this.layoutMode.includes('mobile')
    }
  },
  methods: {
    handleCaptured (e) {
      e.preventDefault()
    },
    handleShowed () {
      const today = new Date()
      const aYearLater = new Date()
      /**
       * Constrain
       * Search period within a year
       */
      aYearLater.setDate(aYearLater.getDate() + 360)
      this.minDate = `${today.getFullYear()}-${(today.getMonth() + 1).toString().padStart(2, '0')}-${today.getDate().toString().padStart(2, '0')}`
      this.maxDate = `${aYearLater.getFullYear()}-${(aYearLater.getMonth() + 1).toString().padStart(2, '0')}-${aYearLater.getDate().toString().padStart(2, '0')}`
    },
    handleFocused (val) {
      this.$nextTick(() => {
        this.calanderVisibility = true
      })
    },
    handleApply (val) {
      this.calanderVisibility = false
      setTimeout(() => {
        this.$emit('input', {
          checkInDate: this.dateOne,
          checkOutDate: this.dateTwo
        })
      }, 300)
    },
    handleOpened (val) {
      // this.calanderVisibility = true
    },
    handleClosed (val) {
      this.calanderVisibility = false
    },
    handleCancelled (val) {
      this.calanderVisibility = false
    },
    selectedDateOne (val) {
      this.dateOne = val
      // this.$emit('input', {
      //   checkInDate: val,
      //   checkOutDate: this.value.checkOutDate
      // })
    },
    selectedDateTwo (val) {
      this.dateTwo = val
      this.$nextTick(() => {
        if (this.dateOne && this.dateTwo && !this.showActionButtons) this.handleApply()
      })
      // this.$emit('input', {
      //   checkInDate: this.value.checkInDate,
      //   checkOutDate: val
      // })
    }
  }
}
</script>

<style lang="scss">
  .daterange--wrapper {
    padding: 0 !important;
  }
</style>
