<template>
  <!-- <div :style="[ visibility ]"> -->
  <div v-loading="isLoading">
    <div class="gap-x2"></div>
    <div :style="[ visibility ]">
      <div><i class="el-icon-warning is-size-1 has-text-grey-light"/></div>
      <div class="gap"></div>
      <div v-html="message"></div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EmptyState',
  props: {
    isLoading: {
      type: Boolean,
      default: false
    },
    message: {
      type: String,
      default: 'It\'s empty'
    }
  },
  computed: {
    visibility () {
      return this.isLoading ? { visibility: 'hidden' } : {}
    }
  }
}
</script>
