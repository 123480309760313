import cookies from 'js-cookie'

export default {
  debug: process.env.NODE_ENV === 'development',
  state: {
    fromRoute: undefined,
    app: {
      title: process.env.VUE_APP_TITLE,
      brand: process.env.VUE_APP_BRAND,
      owner: process.env.VUE_APP_OWNER
    },
    affiliate: {
      code: undefined
    },
    meta: {
      currency: process.env.VUE_APP_DEFAULT_CURRENCY,
      cusToken: null
    },
    globalModal: {
      show: false,
      content: ''
    },
    queryParams: {
      checkInDate: '',
      checkOutDate: ''
    },
    searchMany: {
      selected: '',
      isSearching: false,
      availability: { results: [] }
    },
    searchOne: {
      propertyCode: undefined,
      isSearching: false,
      continuePolling: true,
      property: undefined,
      reviews: undefined,
      availability: undefined
    }
  },
  setFromRoute (newValue) {
    this.state.fromRoute = newValue
  },
  setAffCodeAction (newValue = '') {
    if (this.debug) console.log('setAffCodeAction triggered with', newValue)
    this.state.affiliate.code = newValue
  },
  setMetaAction (newValue = {}) {
    if (this.debug) console.log('setMetaAction triggered with', newValue)
    this.state.meta = newValue
    if (newValue.currency) {
      cookies.set('prfs', { currency: newValue.currency }, { expires: 14 })
    }
    if (newValue.hasOwnProperty('cusToken') || newValue.hasOwnProperty('customer')) {
      const stringify = JSON.stringify({
        ...JSON.parse(localStorage.getItem(process.env.VUE_APP_ACCESS_KEY)),
        cusToken: newValue.cusToken,
        customer: newValue.customer })
      localStorage.setItem(process.env.VUE_APP_ACCESS_KEY, stringify)
    }
  },
  clearMetaAction () {
    if (this.debug) console.log('clearMetaAction triggered')
    this.state.meta = {}
  },
  setQueryParams (newValue) {
    if (this.debug) console.log('setQueryParams triggered with', newValue)
    this.state.queryParams = { ...this.state.queryParams, ...newValue }
  },
  setSearchMany (newValue) {
    if (this.debug) console.log('setSearchMany triggered with', newValue)
    this.state.searchMany = { ...this.state.searchMany, ...newValue }
  },
  clearSearchMany () {
    if (this.debug) console.log('clearSearchMany triggered')
    this.state.searchMany = { selected: '', availability: { results: [] } }
  },
  setSearchOne (newValue) {
    if (this.debug) console.log('setSearchOne triggered with', newValue)
    this.state.searchOne = { ...this.state.searchOne, ...newValue }
  },
  clearSearchOne () {
    if (this.debug) console.log('clearSearchOne triggered')
    this.state.searchOne = {
      propertyCode: undefined,
      isSearching: false,
      continuePolling: true,
      property: undefined,
      reviews: undefined,
      availability: undefined
    }
  },
  setGloablModal (props) {
    if (this.debug) console.log('setGloablModal triggered with', props)
    this.state.globalModal = props
  },
  getAuthorizationHeader () {
    if (this.state.meta.cusToken != null) {
      return { 'authorization': `Bearer ${this.state.meta.cusToken}` }
    }
  }
  // get meta () {
  //   return this.state.meta
  // }
}

// const k = new Vue({
//   data: {
//     debug: process.env.NODE_ENV === 'development',
//     state: {
//       meta: { }
//     }
//   }
// })

// export default k
