<template>
  <div>
    <section class="section is-mini col-search-bar"
      v-sticky="{ zIndex: 2001, stickyTop: 0 }">
      <div class="container is-desktop">
        <keep-alive>
          <component
            :queryParams="lv2State.queryParams"
            :is="searchBar"
            :layoutMode="$mq.all"
            @search="handleSearch"/>
        </keep-alive>
      </div>
    </section>
    <i id="results-con" />
    <section class="section is-mini">
      <div class="container is-desktop">
        <div class="col-filter"
          v-if="$mq.tablet"
          v-sticky="{ zIndex: 2000, stickyTop: 64 }">
          <router-link
            class="map-link"
            :to="{ name: 'searchMap', params: lv2State.queryParams, query: $route.query }">
            <img src="@/assets/map-icon.png" />
            <span class="label">Map view</span>
          </router-link>
          <SearchManyFilters @search="handleSearch" :layoutMode="$mq.all"/>
        </div>
        <div class="col-gap"></div>
        <div class="col-list">
          <SearchManyListSorting
            :layoutMode="$mq.all"
            @search="handleSearch"/>
          <PropertyList class="results"
            imageSize="large"
            :nights="nights"
            :isLoading="isSearching"
            :availability="availability"
            :layoutMode="$mq.all"
            @selected="handleSelectedProperty"
            @heroImageClicked="showPreviewModal"
          />
          <SearchManyPagination @search="handleSearch" v-if="!isSearching" v-bind="availability.pagination" />
        </div>
      </div>
    </section>
    <transition name="view-fade-slow">
      <router-view class="search-bar-mobile--pop" :params="params" :query="query"></router-view>
    </transition>
    <transition name="view-fade">
      <PpOverlay
        v-if="$route.name === 'searchManyPreview'"
        style="background-color: rgba(0,0,0,.8)"
        :zIndex="2002"
        />
    </transition>
  </div>
</template>

<script>
import factories from '../factories'
import SearchManyFilters from '../components/SearchManyFilters'
import SearchManyListSorting from '../components/SearchManyListSorting'
import PropertyList from '../components/PropertyList'
import SearchManyPagination from './SearchManyPagination'
import EventBus from '../EventBus'

export default {
  name: 'SearchManyList',
  components: {
    'SearchBarMany': () => import(/* webpackChunkName: "tablet" */ '../components/SearchBarMany'),
    'SearchBarManyMobile': () => import(/* webpackChunkName: "mobile" */ '../components/SearchBarManyMobile'),
    [SearchManyFilters.name]: SearchManyFilters,
    [SearchManyListSorting.name]: SearchManyListSorting,
    [PropertyList.name]: PropertyList,
    [SearchManyPagination.name]: SearchManyPagination
  },
  props: {
    query: Object,
    params: Object
  },
  data () {
    return {
      nights: 1,
      previewModalVisibility: false
    }
  },
  computed: {
    searchBar () {
      return this.$mq.mobile ? 'SearchBarManyMobile' : 'SearchBarMany'
    },
    availability () {
      return this.lv2State.searchMany.availability
    },
    isSearching () {
      return this.lv2State.searchMany.isSearching
    }
  },
  created () {
    if (!this.lv2State.searchMany.isSearching && this.availability.results.length === 0) {
      this.$lv2Store.setQueryParams({ ...this.$route.query, ...this.$route.params })
      /**
       * Optimize for pre-rendering
       * Check vue.config.js PrerenderSPAPlugin for more details
       */
      if (this.params.locationQuery !== 'blank') factories.many(this.$route.query)
    }
  },
  activated () {
    EventBus.$on('currency-changed', ({ currency } = {}) => {
      this.handleSearch({ currency }, true)
    })
  },
  deactivated () {
    EventBus.$off('currency-changed')
    // source.search.cancel('new search')
  },
  beforeDestroy () {
    // EventBus.$off('currency-changed')
    // this.source.cancel('new search')
  },
  watch: {
    '$route': {
      handler (to, from) {
        if (from.name === 'home') {
          this.$lv2Store.setQueryParams({ ...to.query, ...to.params })
          /**
           * Optimize for pre-rendering
           * Check vue.config.js PrerenderSPAPlugin for more details
           */
          if (this.params.locationQuery !== 'blank') factories.many(to.query)
        }
      }
    }
  },
  methods: {
    // Multiple parties calling this. e.g: SearchFilters & SearchBar
    // replace use on when currency change
    handleSearch (val, replace) {
      const newQuery = { page: 1, ...this.lv2State.queryParams, ...this.$route.query, ...val }
      this.k = newQuery.page
      const { regionName, locationQuery, ...query } = newQuery
      // if (replace) {
      this.$lv2Store.setQueryParams(newQuery)
      factories.many(query)
      // console.log('replaceSearchreplaceSearchreplaceSearchreplaceSearch')
      this.$router.replace({ name: 'searchMany', params: { locationQuery: regionName || locationQuery }, query })
      // } else {
      //   console.log('pushsearchpushsearchpushsearchpushsearchpushsearch')
      //   this.$lv2Store.setQueryParams(newQuery)
      //   factories.many(query)
      //   this.$router.push({ name: 'searchMany', params: { locationQuery: regionName || locationQuery }, query })
      // }
    },
    handleSelectedProperty ({ property, packages }) {
      const joinedGallery = [].concat(
        [{ s: property.heroImage, m: property.heroImage }],
        property.gallery)
      this.$lv2Store.setSearchOne({ property: { ...property, gallery: joinedGallery }, availability: { packages }, reviews: property.reviews })
    },
    showPreviewModal ({ property, packages }) {
      const joinedGallery = [].concat(
        [{ s: property.heroImage, m: property.heroImage }],
        property.gallery)
      this.$lv2Store.setSearchOne({ property: { ...property, gallery: joinedGallery }, availability: { packages }, reviews: property.reviews })
      this.$nextTick(() => {
        this.$router.push({ name: 'searchManyPreview', params: this.$route.params, query: { ...this.$route.query, propertyCode: property.propertyCode, name: property.name } })
      })
      // this.previewModalVisibility = true
    }
  },
  metaInfo () {
    return {
      title: `List: ${this.params.locationQuery} accommodations`,
      titleTemplate: (title) => title
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../styles/bulma-variables';
  .search-bar-mobile--pop {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: $_jh_fullscreen-z-index + 6;
    display: flex;
    flex-direction: column;
    width: 100vw;
  }
  .map-link {
    opacity: 0.68;
    display: block;
    margin-bottom: $bleed !important;
    position: relative;
    border-radius: $bleed;
    overflow: hidden;
    .label {
      position: absolute;
      bottom: 0;
      z-index: 1;
      display: inline-block;
      right:0;
      padding: $bleed $bleed * 2;
      background-color: $primary;
      opacity: 0.80;
      color: $white;
    }
    img {
      display: block;
    }
    &:hover {
      opacity: 0.9;
    }
    &:active {
      opacity: 1;
    }
  }

  .col-search-bar {
    // transform: perspective(0px);
    @include tablet {
      background-color: $white;
      box-shadow: $list-border-shadow;
      padding-bottom: 0 !important;
      & > .container {
        padding: 0 $bleed;
      }
    }
    @include mobile {
      border-top: 1px solid $list-border-color;
      background-color: $white;
      box-shadow: $list-border-shadow;
      padding: 0 !important;
    }
  }
  .col-content {
    // display: flex;
  }
  .col-filter {
    width: 240px;
    padding: 0 $bleed $bleed $bleed;
    float: left;
    transform: perspective(0px);
    height: calc(100vh - 70px);
    overflow: scroll;
  }
  .col-gap {
    display: none;
    width: $bleed * 3;
    flex: none;
  }
  .col-list {
    @include tablet {
      margin-left: 240px + $bleed * 3;
    }
  }
  .filters {
    padding-top: $bleed !important;
    padding-bottom: $bleed !important;
  }
  .results {
    // padding-top: 0;
  }
  .map-wrapper {
    position: sticky;
    top: 64px;
  }
</style>
