import uuidv4 from 'uuid/v4'
import vueSmoothScroll from 'vue2-smooth-scroll'
import ZuDivider from '@/components/ZuDivider'
import IconBase from '@/components/IconBase'
import EmptyState from '@/components/EmptyState'
import store from '../store'
import VueSticky from 'vue-sticky'

const install = function (Vue) {
  Vue.component('EmptyState', EmptyState)
  Vue.component('ZuDivider', ZuDivider)
  Vue.component('IconBase', IconBase)

  Vue.use(vueSmoothScroll)

  Vue.prototype.$lv2_newSearchId = function () {
    return uuidv4()
  }

  Vue.prototype.$lv2Store = store

  Vue.mixin({
    data () {
      return {
        lv2FacebookLoginEnabled: process.env.VUE_APP_FB_LOGIN_ENABLE === 'true',
        lv2State: store.state
      }
    },
    directives: {
      'sticky': VueSticky
    },
    computed: {
      mx_customer () {
        return this.lv2State.meta.customer
      },
      mx_isLoggedIn () {
        return !!this.mx_customer
      }
    }
  })
  Vue.directive('opacity-zero', function (el, binding) {
    if (binding.value) {
      el.style.opacity = 0
    } else {
      el.style.opacity = 1
    }
  })
  Vue.filter('countDays', function (starDate, options = {
    endDate: new Date(),
    round: 'down'
  }) {
    if (!starDate) return '–'
    if (!options.endDate) return 'N.A'
    const totalMsPerDay = 24 * 60 * 60 * 1000
    const s = new Date(starDate)
    const e = new Date(options.endDate)
    const dayDiff = Math.abs((e - s) / totalMsPerDay)
    switch (options.round) {
      case 'down':
        return Math.floor(dayDiff)
      case 'up':
        return Math.ceil(dayDiff)
      default:
        return dayDiff
    }
  })

  Vue.filter('nightLabel', function (night) {
    return night > 1 ? `${night} nights` : `${Number(night >= 0)} night`
  })

  // Convert firestore server timestamp to date
  Vue.filter('fsdate', function (value, options = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric'
  }) {
    const locale = 'en-US'
    if (!value) return '–'
    if (!value.toDate) return 'N.A'
    const d = value.toDate()
    const intl = new Intl.DateTimeFormat(locale, options)
    return intl.format(d)
  })

  Vue.filter('date', function (val, options = {
    // timeZone: true, // Subtible to pass checkin date in local browser.
    hour12: false,
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric'
  }) {
    const locale = window.navigator.language || 'en-US'
    if (!val) return '–'
    // const { timeZone, ...rOptions } = options
    // Always set timeZone to true unless present in option false
    // const useTimeZone = timeZone === undefined ? true : timeZone
    const d = new Date(val)
    // if (useTimeZone) {
    // } else {
    //   const _d = val.match(/^(\d{4})-(\d{2})-(\d{2})/g)
    //   d = new Date(_d[0])
    //   // remove timezone
    // }
    // Something worng retrun N/A
    if (!(d.toJSON())) {
      console.error(new TypeError('val must be instance of Date'))
      return 'N.A'
    }
    const intl = new Intl.DateTimeFormat(locale, { ...options, hour12: false })
    return intl.format(d)
  })

  Vue.filter('currency', function (value, options = {
    currency: 'USD',
    factionDigits: 2
  }) {
    const { currency, factionDigits } = options
    const locale = window.navigator.language || 'en-US'
    if (value == null) return '–'
    if (isNaN(value)) return 'N.A'
    const intl = new Intl.NumberFormat(locale, {
      style: 'currency',
      currency,
      maximumFractionDigits: factionDigits,
      minimumFractionDigits: factionDigits
    })
    return intl.format(value)
  })

  Vue.filter('taxesAndFees', function (rate, options = {
    offset: false // If True return the price minus taxesAndFees
  }) {
    const { offset } = options

    return {
      value: offset ? rate.value - rate.taxesAndFeesTotal : rate.taxesAndFeesTotal,
      currency: rate.currency,
      taxesConfident: rate.taxesConfident
    }
  })

  Vue.filter('refundablePaser', function (val) {
    return (val === undefined || val) ? '–' : 'Refundable'
  })

  Vue.filter('pct', function (value, options = {
    factionDigits: 2
  }) {
    const { factionDigits } = options
    const locale = window.navigator.language || 'en-US'
    if (value == null) return '–'
    if (isNaN(value)) return 'N.A'
    const intl = new Intl.NumberFormat(locale, {
      style: 'percent',
      maximumFractionDigits: factionDigits,
      minimumFractionDigits: factionDigits
    })
    return intl.format(value)
  })

  /**
   * @param objVal.currency String - ISO currency code
   * @param objVal.value Number - Number to be parse
   */
  Vue.filter('currencyObj', function (objVal = {}, options = {
    factionDigits: 2
  }) {
    const { currency, value } = objVal
    if (value == null || !currency) return 'N.A'
    if (value == null) return '–'
    if (isNaN(value)) return 'N.A'
    const { factionDigits } = options
    const locale = window.navigator.language || 'en-US'
    const intl = new Intl.NumberFormat(locale, {
      style: 'currency',
      currency,
      maximumFractionDigits: factionDigits,
      minimumFractionDigits: factionDigits
    })
    return intl.format(value)
  })

  /**
   * @param value Number - Number to be parse
   */
  Vue.filter('number', function (value) {
    if (value == null) return '–'
    if (isNaN(value)) return 'N.A'
    const locale = window.navigator.language || 'en-US'
    const intl = new Intl.NumberFormat(locale, {
      style: 'decimal'
    })
    return intl.format(value)
  })

  Vue.filter('foodCode', function (val) {
    if (!val) return 'N.A'
    const k = {
      1: 'Room Only',
      2: 'Free breakfast',
      3: 'Lunch included',
      4: 'Dinner included',
      5: '2 meals (e.g. breakfast and lunch, lunch and dinner)',
      6: 'Breakfast, lunch and dinner',
      7: 'All inclusive'
    }
    return k[val]
  })
}

export default {
  install
}
