import cookies from 'js-cookie'
import Vue from 'vue'
// import ElementUI from 'element-ui'
import store from './store'
import App from './App.vue'
import router from './router'
import VueLazyload from 'vue-lazyload'
import Pp from 'pragmatic-pack'
import AirbnbStyleDatepicker from 'vue-airbnb-style-datepicker'
import TheHeader from '@/components/TheHeader'
import TheFooter from '@/components/TheFooter'
import Essentials from '@/plugins/Essentials'
import ElementUiCustom from '@/plugins/ElementUiCustom'
import ProgressIndicator from 'vue-progress-indicator/src'
import vue2SmoothScroll from 'vue2-smooth-scroll'
import MQ from 'vue-match-media/src'

import 'vue-airbnb-style-datepicker/dist/vue-airbnb-style-datepicker.min.css'
// import './styles/element-ui-theme.scss'
// import 'element-ui/lib/theme-chalk/display.css'

import './styles/bulma.scss'
import './styles/custom.scss'

Vue.config.productionTip = false

Vue.use(MQ)
Vue.use(Pp)
// Vue.use(ElementUI)
Vue.use(VueLazyload, {
  preLoad: 1.6,
  throttleWait: 300,
  attempt: 0,
  listenEvents: ['scroll']
})
Vue.use(ProgressIndicator)
Vue.use(vue2SmoothScroll)
Vue.use(AirbnbStyleDatepicker, {
  sundayFirst: true,
  colors: {
    selected: '#0094f0',
    inRange: '#5fabf3',
    selectedText: '#fff',
    text: '#565a5c',
    inRangeBorder: '#0094f0',
    disabled: '#fff'
  }
})
Vue.use(ElementUiCustom)
Vue.use(Essentials)

Vue.component('TheHeader', TheHeader)
Vue.component('TheFooter', TheFooter)

/**
 * Pre-load prfs and login status
 */
try {
  store.setAffCodeAction(JSON.parse(localStorage.getItem(`aff_${process.env.VUE_APP_CODE}`)).code)
  store.setMetaAction({
    ...cookies.getJSON('prfs'),
    ...JSON.parse(localStorage.getItem(process.env.VUE_APP_ACCESS_KEY))
  })
} catch (err) {
  // Incase the localstorage is poluted, we will reset it
  localStorage.setItem(process.env.VUE_APP_ACCESS_KEY, null)
  store.setMetaAction({
    ...cookies.getJSON('prfs'),
    ...JSON.parse(localStorage.getItem(process.env.VUE_APP_ACCESS_KEY))
  })
}

new Vue({
  router,
  render: h => h(App),
  mq: {
    mobile: '(max-width: 768px)',
    tablet: '(min-width: 769px)',
    tabletOnly: '(min-width: 769px) and (max-width: 1023px)',
    desktop: '(min-width: 1024px)',
    desktopOnly: '(min-width: 1024px) and (max-width: 1215px)',
    widescreen: '(min-width: 1216px)',
    widescreenOnly: '(min-width: 1216px) and (max-width: 1407px)',
    fullhd: '(min-width: 1408px)'
  },
  /**
   * Vue-meta for more information
   */
  metaInfo: {
    titleTemplate: (titleChunk) => {
      // If undefined or blank then we don't need the hyphen
      return titleChunk ? `${titleChunk} - ${process.env.VUE_APP_TITLE}` : process.env.VUE_APP_TITLE
    }
  }
}).$mount('#app')
