// we use requestAnimationFrame to be called by the browser before every repaint
const requestAnimationFrame = window.requestAnimationFrame ||
  window.mozRequestAnimationFrame || window.webkitRequestAnimationFrame ||
  function (fn) {
    window.setTimeout(fn, 16)
  }

const defaultValue = {
  duration: 500,
  offset: 0,
  container: window
}

// Get the top position of an element in the document
const getTop = function (element, start) {
  // return value of html.getBoundingClientRect().top ... IE : 0, other browsers : -pageYOffset
  if (element.nodeName === 'HTML') return -start
  return element.getBoundingClientRect().top + start
}

/**
 * ease in out function
 * @see https://gist.github.com/gre/1650294
 */
const easeInOutCubic = function easeInOutCubic (t) {
  return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1
}

const scrollTo = function (selector, options = {}) {
  let { duration, offset, container } = options
  duration = duration || defaultValue.duration
  offset = offset || defaultValue.offset
  container = container || defaultValue.container

  if (typeof container === 'string') {
    container = document.querySelector(container)
  }
  const scrollTo = document.querySelector(selector)
  if (!scrollTo) return null// Do not scroll to non-existing node

  // Using the history api to solve issue: back doesn't work
  // most browser don't update :target when the history api is used:
  // THIS IS A BUG FROM THE BROWSERS.
  // I dont need this.
  // if (window.history.pushState && location.hash !== this.hash) window.history.pushState('', '', this.hash)

  const startPoint = container.scrollTop || window.pageYOffset
  // Get the top position of an element in the document
  // return value of html.getBoundingClientRect().top ... IE : 0, other browsers : -pageYOffset
  let end = getTop(scrollTo, startPoint)

  // Ajusts offset from the end
  end += offset

  const clock = Date.now()
  const step = function () {
    // the time elapsed from the beginning of the scroll
    const elapsed = Date.now() - clock
    // calculate the scroll position we should be in
    let position = end
    if (elapsed < duration) {
      position = startPoint + (end - startPoint) * easeInOutCubic(elapsed / duration)

      requestAnimationFrame(step)
    } else {
      // location.replace('#' + scrollTo.id)
      // this will cause the :target to be activated.
    }

    container === window ? container.scrollTo(0, position) : (container.scrollTop = position)
  }
  step()
}

export default {
  scrollTo
}
