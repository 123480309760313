<template>
  <transition name="pp-transition--fade"
    @after-enter="handleInited">
    <div class="pp-modal" v-if="value">
      <transition name="pp-transition--slideup">
        <div
          v-if="isLoaderVisible"
          :class="[
            'pp-modal--loader',
            isContentVisible ? 'pp-modal--loader-loaded' : ''
          ]">
          <slot v-if="$slots.loader" name="loader" />
          <div v-else style="background: #fff;">
            <PpSpiner style="width: 130px; height: 130px;" />
            <div v-if="loadingMessage">{{ loadingMessage }}</div>
          </div>
        </div>
      </transition>
      <transition
        :name="'pp-transition--' + contentTransitionType"
        @after-enter="handleExpanded">
        <div v-if="isContentVisible"
          :style="[{ overflow: overflow }]"
          :class="[
            'pp-modal--content',
            isExpanded ? 'pp-modal--content-expended' : ''
          ]">
          <slot />
        </div>
      </transition>
      <PpOverlay
        @click.native.stop.prevent="handleClose()"/>
    </div>
  </transition>
</template>

<script>
import PpOverlay from '../../overlay/src/overlay'
import PpSpiner from '../../loading/src/spiner'
/**
 * TODO: cotroable gap size
 */
export default {
  name: 'PpModal',
  components: {
    [PpOverlay.name]: PpOverlay,
    [PpSpiner.name]: PpSpiner
  },
  props: {
    value: {
      type: Boolean,
      required: true
    },
    /** Support transtion expand | slideup */
    contentTransitionType: {
      type: String,
      default: 'expand'
    },
    isLoading: Boolean,
    loadingMessage: {
      type: String
    },
    isClickOutsideClose: {
      type: Boolean,
      default: false
    },
    overflow: {
      type: String,
      default: 'auto'
    }
  },
  data () {
    return {
      // This to create animation
      isAfterLoading: false,
      isError: false,
      isExpanded: false,
      isInited: false
    }
  },
  computed: {
    isLoaderVisible () {
      if (!this.isLoading) return false
      return this.isInited && (this.isLoading || !this.isExpanded)
    },
    isContentVisible () {
      // If loader not exits, directly show content
      if (!this.isLoading) return this.isInited && true
      // If loader exits
      if (this.isLoading) return this.isInited && !this.isLoading
    }
  },
  created () {
    // Too troblesome
    // if (!this.$slots.default) throw new Error('Missing content slot')

    // When loader is pass it must pass in loader attribute too
    if (this.$slots.loader && this.isLoading) throw new Error('Missing either loader-slot or loader attribute as Promise')
  },
  watch: {
    'value': {
      immediate: true,
      handler (nV, oV) {
        this.$nextTick(() => {
          if (nV) {
            const html = document.querySelector('html')
            html.classList.add('is-clipped')
          } else {
            const html = document.querySelector('html')
            html.classList.remove('is-clipped')
            this.$emit('closed')
          }
          if (nV && this.isLoading) {
            this.isLoading = true
          } else {
            this.resetToInitialState()
            if (nV) this.isInited = true
          }
        })
      }
    }
  },
  beforeDestroy () {
    const html = document.querySelector('html')
    html.classList.remove('is-clipped')
  },
  methods: {
    handleClose () {
      if (this.isClickOutsideClose) {
        const html = document.querySelector('html')
        html.classList.remove('is-clipped')
        this.resetToInitialState()
        this.$emit('input', false)
      }
    },
    afterShowed () {

    },
    handleExpanded () {
      this.isExpanded = true
    },
    handleInited () {
      this.isInited = true
    },
    resetToInitialState () {
      this.isInited = false
      this.isExpanded = false
      this.isError = false
      this.$emit('reseted')
    }
  }
}
</script>
