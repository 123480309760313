<script>
import HomeSearch from './HomeSearch'
import HomeTopCities from './HomeTopCities'

export default {
  name: 'home',
  functional: true,
  render (h, ctx) {
    return [
      h(HomeSearch),
      h(HomeTopCities)
    ]
  }
}
</script>
