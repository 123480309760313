<template>
  <el-autocomplete
    id="location-query"
    popper-class="advance-autocomplete"
    class="inline-input"
    prefix-icon="el-icon-search"
    v-model="value.locationQuery"
    value-key="term"
    :fetch-suggestions="queryAutosuggest"
    :debounce="400"
    :trigger-on-focus="false"
    placeholder="Search city"
    ref="autocomplete"
    :popper-options="{ boundariesElement: 'srcollParent' }"
    :popper-append-to-body="false"
    @select="handleAutosuggestSelect"
    @focus="handleFocus"
  >
    <i
      class="el-icon-close el-input__icon"
      slot="suffix"
      v-show="value.locationQuery"
      @click="handleClearClick">
    </i>
    <template slot-scope="{ item }">
      <div class="list is-gapless">
        <div class="list-middle">
          <i class="el-icon-location" />
        </div>
        <div class="list-content">
          <div>{{ item.term }}</div>
        </div>
      </div>
      <!-- <div class="list" v-if="item.typm pe === 'airport'">
        <div class="list-middle">
          <img :src="item.data.icon" />
        </div>
        <div class="list-content">
          <div>{{ item.data.name }}</div>
          <div class="list-label">{{ item.data.formatted_address }}</div>
        </div>
      </div> -->
      <!-- <div class="list" v-if="item.type === 'trainStation'">
        <div class="list-middle">
          <img :src="item.data.icon" />
        </div>
        <div class="list-content">
          <div>{{ item.data.name }}</div>
          <div class="list-label">{{ item.data.formatted_address }}</div>
        </div>
      </div>
      <div class="list" v-if="item.type === 'address'">
        <div class="list-middle">
          <i class="el-icon-location-outline" />
        </div>
        <div class="list-content">
          <div>{{ item.data.description }}</div>
        </div>
      </div> -->
    </template>
  </el-autocomplete>
</template>

<script>
import lv2, { axiosErrorHandler } from '../lv2Api'
import store from '../store'
import smoothScroll from '../plugins/smoothScroll'

export default {
  name: 'LocationSearch',
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  methods: {
    handleFocus (e) {
      smoothScroll.scrollTo('#location-query', { offset: -20 })
    },
    handleClearClick () {
      this.$emit('input', {
        regionId: undefined,
        locationQuery: ''
      })
      this.$refs.autocomplete.focus()
    },
    async queryAutosuggest (queryString, cb) {
      const blankResults = []
      const trimed = queryString.trim()
      if (trimed === '') return cb(blankResults)
      try {
        const { data } = await lv2.get('/autosuggest', { params: { q: trimed, currency: this.lv2State.meta.currency } })
        const { outlets, meta } = data
        store.setMetaAction(meta)
        // console.log(outlets)
        // this.autosuggestResults.region = outlets.results
        // this.autosuggestResults.address = data.address.predictions
        // this.autosuggestResults.airport = data.airport.results
        // this.autosuggestResults.trainStation = data.trainStation.results

        if (data === null) return cb(blankResults)
        // const { city_en_us = empty } = data.results || {}
        return cb(outlets.results)
      } catch (err) {
        const parseErr = axiosErrorHandler(err)
        store.setMetaAction(parseErr.meta)
        store.setGloablModal({ show: true, content: parseErr.message || parseErr.error.message })
        return cb(blankResults)
      }
    },
    handleAutosuggestSelect (val) {
      const { term, id } = val
      this.$refs.autocomplete.focus()
      this.$emit('input', {
        regionId: id,
        locationQuery: term,
        regionName: term
      })
    }
  }
}
</script>

<style lang="scss">
  .el-autocomplete-suggestion li {
    padding: 0 6px !important;
  }
</style>
