import { render, staticRenderFns } from "./FiltersPriceRange.vue?vue&type=template&id=2063b554&scoped=true&"
import script from "./FiltersPriceRange.vue?vue&type=script&lang=js&"
export * from "./FiltersPriceRange.vue?vue&type=script&lang=js&"
import style0 from "./FiltersPriceRange.vue?vue&type=style&index=0&id=2063b554&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2063b554",
  null
  
)

component.options.__file = "FiltersPriceRange.vue"
export default component.exports