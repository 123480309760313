<template>
  <div class="section is-small">
      <div class="columns pagi-con is-mobile is-multiline is-gapless">
        <div class="column is-3-mobile">
          <a class="is-size-7" href="#results-con" v-smooth-scroll="{
            duration: 500
          }">Back to top</a>
        </div>
        <div
          v-if="totalItems"
          class="column has-text-right">
          <span class="is-size-7">
          Showing Results {{ showingNow }}
          </span>
        </div>
        <div class="column is-12 is-hidden-tablet">
          <PpGap />
        </div>
        <div class="column is-12-mobile has-text-centered-mobile has-text-right">
          <el-pagination
            class="pagi"
            v-if="totalItems"
            background
            layout="prev, pager, next"
            :pagerCount="pagerCount"
            :pageSize="pageSize"
            :currentPage="page"
            :total="totalItems"
            @current-change="handlePageChange">
          </el-pagination>
        </div>
      </div>
  </div>
</template>

<script>
export default {
  name: 'SearchManyPagination',
  props: {
    totalItems: {
      type: Number
    },
    page: {
      type: Number
    },
    showing: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      pageSize: 20
    }
  },
  computed: {
    showingNow () {
      return `${this.showing[0]} – ${this.showing[1]} of ${this.totalItems}`
    },
    pagerCount () {
      return this.$mq.mobile ? 5 : 7
    }
  },
  methods: {
    handlePageChange (pg) {
      this.$emit('search', { page: pg })
    }
  }
}
</script>

<style lang="scss" scoped>
  .pagi-con {
    align-items: center;
  }
  .pagi {
    padding: 0 !important;
  }
</style>
