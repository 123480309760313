import lv2, { CancelToken, isCancel, axiosErrorHandler } from './lv2Api'
import store from './store'

const debug = process.env.NODE_ENV === 'development'
export const source = {
  many: CancelToken.source(),
  one: CancelToken.source()
}

export const searchMany = (query) => {
  source.many.cancel('NEW_SEARCH_MANY')
  source.many = CancelToken.source()
  store.setSearchMany({ isSearching: true })

  return lv2.get('/search', {
    params: query,
    cancelToken: source.many.token
  })
    .then(({ data }) => {
      const { outlets, meta } = data
      store.setMetaAction(meta)
      store.setSearchMany({ isSearching: false, ...outlets })
    })
    .catch((err) => {
      if (isCancel(err)) {
        if (debug) console.warn(`Cancel: ${err.message}`)
        if (err.message !== 'NEW_SEARCH_MANY') {
          store.setSearchMany({ isSearching: false, availability: { results: [] } })
        }
        return
        // throw new Error('USER_CANCEL_REQUEST')
      }
      const parseErr = axiosErrorHandler(err)
      store.setMetaAction(parseErr.meta)
      store.setSearchMany({ isSearching: false })
      store.setGloablModal({ show: true, content: parseErr.message || parseErr.error.message })
    })
}

export const searchOne = (query, _params) => {
  source.one = CancelToken.source()
  store.setSearchOne({ isSearching: true })

  return lv2.get(`/search/${query.propertyCode}`, {
    params: query,
    cancelToken: source.one.token
  })
    .then(({ data }) => {
      const { outlets, meta } = data
      store.setMetaAction(meta)
      store.setSearchOne({ isSearching: false, ...outlets })
      return outlets
    })
    .catch((err) => {
      if (isCancel(err)) {
        if (debug) console.warn(`Cancel: ${err.message}`)
        if (err.message !== 'NEW_SEARCH_ONE') {
        }
        return
        // throw new Error('USER_CANCEL_REQUEST')
      }
      const parseErr = axiosErrorHandler(err)
      store.setMetaAction(parseErr.meta)
      store.setSearchOne({ isSearching: false })
      store.setGloablModal({ show: true, content: parseErr.message || parseErr.error.message })
    })
}

export default {
  source,
  many: searchMany,
  one: searchOne
}
