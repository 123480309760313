<template>
  <div :class="['list is-gapless is-topless is-bottomless', isMobile ? 'layout-slim' : '']"
    v-if="isMobile">
    <!-- <ul class="many-sort list-action" style="padding: 0;">
      <li :class="['many-sort--item many-sort--link']"
      style="white-space: nowrap;line-height: 32px;">
      <router-link
        class="map-link"
        :to="{ name: 'searchMap', params: $route.params, query: $route.query }">
        <img src="@/assets/map-icon.png" />
      </router-link>
      </li>
    </ul> -->
    <div class="list-action">
      <router-link
        v-if="$route.name === 'searchMany'"
        class="map-link"
        :to="{ name: 'searchMap', params: lv2State.queryParams, query: $route.query }">
        <i class="el-icon-location" />&nbsp;Map View
      </router-link>
      <PpHistoryLink
        v-if="$route.name === 'searchMap'"
        :route="{ name: 'searchMany', params: lv2State.queryParams, query: $route.query }"
        :historyBack="true"
        label="Close map">
        <i class="el-icon-close" />&nbsp;
        <span class="svg-label">Close map</span>
      </PpHistoryLink>

    </div>

    <div class="list-content"></div>
    <ul class="many-sort list-action" style="padding: 0;">
      <li :class="['many-sort--item many-sort--link', sortBy !== 'popular' && 'many-sort--active']"
        style="white-space: nowrap;">
        <span>{{ localSort.label }}&nbsp;<i class="el-icon-arrow-down" /></span>
        <select
          style="position: absolute; opacity: 0; width: 100%; height: 100%"
          v-model="sortBy">
          <option
            class="is-size-6"
            v-for="it in sortOptions"
            :key="it.key"
            :value="it.key">{{ it.label }}</option>
        </select>
      </li>
    </ul>
  </div>
  <ul
    v-else
    class="many-sort">
    <li class="many-sort--item many-sort--sortby">
      <span class="has-text-weight-semibold has-text-dark">Sort by:</span>
    </li>
    <li :class="['many-sort--link many-sort--item', sortBy === 'popular' && 'many-sort--active']"
      @click="handleSorted('popular')">
      <span>Popularity</span>
    </li>
    <li :class="['many-sort--link many-sort--item', sortBy === 'adjustment' && 'many-sort--active']"
      @click="handleSorted('adjustment')">
      <span>Discount</span>
    </li>
    <li
      :class="['many-sort-byprice many-sort--link many-sort--item', ['priceDsc','priceAsc'].includes(sortBy) && 'many-sort--active']"
      @mouseover="subPriceVisibility = true"
      @mouseout="subPriceVisibility = false"
      @click="handleSorted(sortBy === 'priceAsc' ? 'priceAsc' : 'priceDsc')"
      >
      <span class="has-text-nowrap" v-if="sortBy === 'priceAsc'">Price, highest first <i class="el-icon-caret-bottom" /></span>
      <span  class="has-text-nowrap"  v-else>Price, lowest first <i class="el-icon-caret-bottom" /></span>
      <transition name="view-slide-down">
        <ul
          v-if="subPriceVisibility"
          class="many-sort--sub">
          <li
            v-if="sortBy === 'priceAsc'"
            :class="['many-sort--sub-item']"
            @click.capture.stop="handleSorted('priceDsc')">
            <span>Price, lowest first</span>
          </li>
          <li
            v-else
            :class="['many-sort--sub-item']"
            @click.capture.stop="handleSorted('priceAsc')">
            <span>Price, highest first</span>
          </li>
        </ul>
      </transition>
    </li>
    <li :class="['many-sort--link many-sort--item', sortBy === 'trustYouDsc' && 'many-sort--active']"
      @click="handleSorted('trustYouDsc')">
      <span>Reviews</span>
    </li>
  </ul>
</template>

<script>
import IconList from '../components/icons/IconList'

const genericOptions = [
  { key: 'popular', label: 'Popularity', icon: 'Popularity' },
  // { key: 'customAsc', label: 'Custom Asc' },
  // { key: 'customDsc', label: 'Custom Desc' },
  { key: 'adjustment', label: 'Discount', icon: 'Discount' },
  { key: 'priceDsc', label: 'Price, lowest first', icon: 'Price<i class="el-icon-caret-bottom" />' },
  { key: 'priceAsc', label: 'Price, highest first', icon: 'Price<i class="el-icon-caret-top" />' },
  { key: 'trustYouDsc', label: 'Reviews', icon: 'Trustyou rating' }
  // { key: 'starRatingAsc', label: 'Star, high to low', icon: 'Star rating<i class="el-icon-caret-top" />' },
  // { key: 'starRatingDsc', label: 'Star, low to high', icon: 'Star rating<i class="el-icon-caret-bottom" />' }
]
const findOption = (sort) => {
  return genericOptions.find(ele => ele.key === sort) || genericOptions[0]
}

export default {
  name: 'SearchManyListSorting',
  components: {
    'IconList': IconList
  },
  props: {
    layoutMode: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      subPriceVisibility: false,
      sortOptions: genericOptions
    }
  },
  computed: {
    isMobile () {
      return this.layoutMode.includes('mobile')
    },
    localSort () {
      return this.sortBy ? findOption(this.sortBy) : genericOptions[0]
    },
    sortBy: {
      get () {
        return this.$route.query.sortBy || 'popular'
      },
      set (val) {
        this.$emit('search', this.getQuery(val))
      }
    }
  },
  methods: {
    handleSortClicked () {
      this.$refs.eleSortDropdown.click()
    },
    handleSorted (key) {
      this.sortBy = key
    },
    getQuery (val) {
      return {
        page: 1,
        sortBy: val
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../styles/bulma-variables';
  $many-sort--sub-item-color: $grey;
  $many-sort--height: 48px;
  $many-sort--mobile-height: 32px;
  .icon-svg {
    width: 1.25em;
    height: 1.25em;
    vertical-align: middle;
    margin-bottom: 2px;
  }
  .svg-label {
    display: inline-block;
    vertical-align: middle;
  }
  .map-link {
    line-height: $many-sort--mobile-height;
    display: block;
    height: $many-sort--mobile-height;
    img {
      height: 100%;
    }
  }
  .many-sort {
    border-radius: $bleed;
    display: flex;
    margin: $bleed;
    height: $many-sort--height;
    background-color: $light;
    box-shadow: $border-shadow;
  }
  .layout-slim .many-sort {
    height: $many-sort--mobile-height;
  }
  .many-sort--sub {
    position: absolute;
    top:  $many-sort--height;
    border-top: 1px solid #fff;
    box-shadow: 0 1px 1px rgba(60, 64, 67, 0.302);
    width: 100%;
    transform: perspective(0px);
    z-index: 1;
    font-weight: initial;
    .many-sort--sub-item{
      height: $many-sort--height;
      background-color: $white;
      padding: $bleed $bleed * 3;
      cursor: pointer;
      display: flex;
      align-items: center;
      color: $many-sort--sub-item-color;
      justify-content: center;
      transition: 80ms background-color linear;
      &:hover {
        color: $link-hover;
      }
      &:active {
        background-color: $light;
      }
    }
  }
  .many-sort--item {
    position: relative;
    flex: 1;
    background-color: $white;
    align-items: center;
    display: flex;
    justify-content: center;
    // font-size: 1rem;
    font-size: 0.85em;
    padding: $bleed $bleed * 3;
    @include mobile {
      select {
        font-size: 16px !important;
      }
      padding: $bleed $bleed * 2;
    }
    cursor: pointer;
    transition: 80ms background-color linear;
    &:first-child {
      border-top-left-radius: $bleed;
      border-bottom-left-radius: $bleed;
    }
    &:last-child {
      border-top-right-radius: $bleed;
      border-bottom-right-radius: $bleed;
    }
  }
  .many-sort--link {
    &:hover {
      color: $link-hover;
      box-shadow: $border-shadow;
      z-index: 2;
    }
    &:not(.many-sort--has-sub):active {
      background-color: $light;
    }
    &.many-sort--active {
      background-color: $primary;
      color: $white;
      font-weight: 600;
    }
  }
  .many-sort--sub .many-sort--item-disabled {
    cursor: initial;
    pointer-events: none;
    color: $grey-lighter;
  }
  .many-sort-byprice {
    flex: none;
    // min-width: 175px;
  }
  .many-sort--sortby {
    flex: none;
    min-width: 80px;
    padding: $bleed $bleed * 2;
  }
</style>
