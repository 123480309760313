<template>
  <article
    :class="['message', boxColor]">
    <div class="message-body">
      <CancellationParse :freeCancellationBefore="freeCancellationBefore" />
    </div>
  </article>
</template>

<script>
import CancellationParse from './CancellationParse'

export default {
  name: 'CancellationPenalty',
  components: {
    [CancellationParse.name]: CancellationParse
  },
  props: {
    freeCancellationBefore: {
      required: true
    }
  },
  computed: {
    boxColor () {
      return this.freeCancellationBefore ? 'is-success' : 'is-info'
    }
  }
}
</script>
