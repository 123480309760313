<template>
  <section class="section home-top-cities">
    <div class="container is-desktop">
      <h6 class="title is-5">Top Cities</h6>
      <div class="columns is-multiline cities-list">
        <router-link
          class="column is-3"
          :to="{
            name: 'searchMany',
            params: {
              locationQuery: it.city + ', ' + it.country,
            },
            query: Object.assign({}, genericQuery, { regionId: it.cityCode, searchId: $lv2_newSearchId() })
          }"
          v-for="it in cities"
          :key="it.cityCode">
          {{ it.city }}, {{ it.country}}
        </router-link>
      </div>
    </div>
  </section>
</template>

<script>
const topCities = [
  {
    countryCode: 'HK',
    country: 'Hong Kong SAR',
    city: 'Kowloon',
    cityCode: '6059',
    continent: 'asia'
  },
  {
    countryCode: 'CN',
    country: 'China',
    city: 'Shanghai',
    cityCode: '3145',
    continent: 'asia'
  },
  {
    countryCode: 'CN',
    country: 'China',
    city: 'Beijing',
    cityCode: '597',
    continent: 'asia'
  },
  {
    countryCode: 'TW',
    country: 'Taiwan',
    city: 'Taipei',
    cityCode: '3518',
    continent: 'asia'
  },
  {
    countryCode: 'MY',
    country: 'Malaysia',
    city: 'Kuala Lumpur',
    cityCode: '1951',
    continent: 'asia'
  },
  {
    countryCode: 'TH',
    country: 'Thailand',
    city: 'Bangkok',
    cityCode: '604',
    continent: 'asia'
  },
  {
    countryCode: 'PH',
    country: 'Phillippines',
    city: 'Manila',
    cityCode: '2375',
    continent: 'asia'
  },
  {
    countryCode: 'JP',
    country: 'Japan',
    city: 'Tokyo',
    cityCode: '3593',
    continent: 'asia'
  },
  {
    countryCode: 'JP',
    country: 'Japan',
    city: 'Kyoto',
    cityCode: '10323',
    continent: 'asia'
  },
  {
    countryCode: 'SG',
    country: 'Singapore',
    city: 'Singapore',
    cityCode: '3168',
    continent: 'asia'
  },
  {
    countryCode: 'AU',
    country: 'Australia',
    city: 'Sydney',
    cityCode: '3341',
    continent: 'asia'
  }
]
export default {
  name: 'HomeTopCities',
  data () {
    return {
      cities: topCities
    }
  },
  computed: {
    genericQuery () {
      const now = new Date()
      now.setDate(now.getDate() + 5)
      const later = new Date(now)
      later.setDate(later.getDate() + 1)

      return {
        checkInDate: now.toJSON().split('T')[0],
        checkOutDate: later.toJSON().split('T')[0],
        adultCount: 2,
        roomCount: 1,
        currency: this.lv2State.meta.currency,
        page: 1
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../styles/bulma-variables';
  .cities-list {
    .column {
      padding: $bleed / 2 $bleed * 2;
    }
  }
  .home-top-cities {
    min-height: 400px;
  }
</style>
