import Vue from 'vue'
import Meta from 'vue-meta'
import Router from 'vue-router'
import Home from './views/Home.vue'
import SearchOne from './views/SearchOne.vue'
import SearchManyList from './views/SearchManyList.vue'
import store from './store'

Vue.use(Router)
Vue.use(Meta)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
      meta: {
        scrollToTop: true
      }
    },
    {
      path: '/search/:locationQuery',
      name: 'searchMany',
      props: (route) => ({ query: { ...route.query, regionName: route.params.locationQuery }, params: route.params }),
      component: SearchManyList,
      meta: {
        keepAlive: true,
        scrollToPos: { x: 0, y: 60 }
      },
      children: [
        {
          path: 'preview',
          name: 'searchManyPreview',
          component: () => import(/* webpackChunkName: "mobiledefer" */ './views/PtyPreview'),
          meta: {
            keepAlive: true
          }
        }
      ]
    },
    {
      path: '/map/:locationQuery',
      name: 'searchMap',
      props: (route) => ({
        query: { ...route.query, regionName: route.params.locationQuery },
        params: route.params
      }),
      component: () => import(/* webpackChunkName: "map" */ './views/SearchManyMap.vue'),
      meta: {
        keepAlive: true,
        hasHeaderFullWidth: true,
        scrollToPos: { x: 0, y: 60 },
        hasFooterHidden: true
      },
      children: [
        {
          path: 'preview',
          name: 'searchMapPreview',
          component: () => import(/* webpackChunkName: "mobiledefer" */ './views/PtyPreview'),
          meta: {
            keepAlive: true
          }
        }
      ]

    },
    {
      path: '/property/:name',
      name: 'property',
      props: (route) => ({ query: route.query, params: route.params }),
      component: SearchOne,
      meta: {
        keepAlive: true,
        scrollToPos: { x: 0, y: 60 }
      }
    },
    {
      path: '/checkout/:ckToken',
      name: 'checkoutForm',
      // props: (route) => ({ query: route.query, params: route.params }),
      component: () => import(/* webpackChunkName: "checkout" */ './views/CheckoutForm.vue'),
      meta: {
        scrollToPos: { x: 0, y: 0 }
      }
    },
    {
      path: '/checkout/express/:ordToken',
      name: 'checkoutComplete',
      component: () => import(/* webpackChunkName: "checkout" */ './views/CheckoutComplete.vue'),
      meta: {
        scrollToPos: { x: 0, y: 0 }
      }
    },
    {
      path: '/about',
      name: 'about',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "static" */ './views/static/About.vue'),
      meta: {
        scrollToPos: { x: 0, y: 0 }
      }
    },
    {
      path: '/terms',
      name: 'terms',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "static" */ './views/static/Terms.vue'),
      meta: {
        scrollToPos: { x: 0, y: 0 }
      }
    },
    // https://www.facebook.com/v3.2/dialog/oauth?%20client_id=439725643220371&redirect_uri=https%3A%2F%2Flocalhost:8080/customer/auth/facebook&auth_type=rerequest&scope=email
    // https://www.facebook.com/v3.2/dialog/oauth?%20client_id=439725643220371&redirect_uri=https%3A%2F%2Flocalhost:8080/customer/auth/facebook&auth_type=rerequest&scope=email&response_type=code
    // open_kxifnjm_user@tfbnw.net
    {
      path: '/customer/auth/:provider',
      name: 'auth',
      props: (route) => ({
        query: route.query,
        provider: route.params.provider
      }),
      component: () => import(/* webpackChunkName: "customer" */ './views/customer/auth.vue'),
      meta: {
        scrollToPos: { x: 0, y: 0 }
      }
    },
    {
      path: '/customer/login',
      name: 'login',
      props: (route) => ({
        query: route.query,
        params: route.params
      }),
      component: () => import(/* webpackChunkName: "customer" */ './views/customer/login.vue'),
      meta: {
        scrollToPos: { x: 0, y: 0 }
      },
      beforeEnter: (_to, _from, next) => {
        if (store.state.meta.customer) {
          return next('/')
        }
        next()
      }
    },
    {
      path: '/customer/signup',
      name: 'signup',
      props: (route) => ({
        query: route.query,
        params: route.params
      }),
      component: () => import(/* webpackChunkName: "customer" */ './views/customer/signup.vue'),
      meta: {
        scrollToPos: { x: 0, y: 0 }
      },
      beforeEnter: (_to, _from, next) => {
        if (store.state.meta.customer) {
          return next('/')
        }
        next()
      }
    },
    {
      path: '/customer/forgot-password',
      name: 'forgotPassword',
      props: (route) => ({
        query: route.query,
        params: route.params
      }),
      component: () => import(/* webpackChunkName: "customer" */ './views/customer/ForgotPassword.vue'),
      meta: {
        scrollToPos: { x: 0, y: 0 }
      },
      beforeEnter: (_to, _from, next) => {
        if (store.state.meta.customer) {
          return next('/')
        }
        next()
      }
    },
    {
      path: '/customer/new-password',
      name: 'newPassword',
      props: (route) => ({
        query: route.query,
        token: route.query.actionToken,
        action: route.query.action
      }),
      component: () => import(/* webpackChunkName: "customer" */ './views/customer/NewPassword.vue'),
      meta: {
        scrollToPos: { x: 0, y: 0 }
      },
      beforeEnter: (_to, _from, next) => {
        if (store.state.meta.customer) {
          return next('/')
        }
        next()
      }
    }
  ],
  scrollBehavior (to, _from, savedPosition) {
    if (savedPosition) {
      return Promise.resolve(savedPosition)
    } else {
      const position = {}
      return new Promise(resolve => {
        // check if any matched route config has meta that requires scrolling to top
        if (to.matched.some(m => m.meta.scrollToPos)) {
          // coords will be used if no selector is provided,
          // or if the selector didn't match any element.
          if (to.meta.scrollToPos) {
            position.x = to.meta.scrollToPos.x
            position.y = to.meta.scrollToPos.y
          }
        }
        resolve(position)
      })
    }
  }
})

router.afterEach((_to, from) => {
  store.setFromRoute(from)
})

export default router
