<script>
export default {
  name: 'PpGap',
  functional: true,
  props: {
    size: {
      type: String,
      default: 'normal' // tiny, small, normal, big, large
    }
  },
  render (h, ctx) {
    const css = `pp-gap--${ctx.props.size}`
    return h('div', { class: ['pp-gap', css, ctx.data.staticClass] })
  }
}
</script>
