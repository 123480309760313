<template>
  <div class="hero is-medium bg-image">
    <div class="hero-body">
    <div class="container is-desktop">
      <h1 class="subtitle is-2">Exclusive rates just for you</h1>
      <SearchBarMany
        :queryParams="lv2State.queryParams"
        @search="handleSearch"/>
    </div>
    </div>
  </div>
</template>

<script>
import factories from '../factories'

export default {
  name: 'HomeSearch',
  components: {
    'SearchBarMany': () => import(/* webpackChunkName: "tablet" */ '../components/SearchBarMany')
  },
  data () {
    return {
      query: {}
    }
  },
  metaInfo: {
    // title: 'Exclusive rates'
  },
  methods: {
    handleSearch (val) {
      this.query = { ...this.query, locationQuery: val.regionName, ...val }
      const { regionName, ...query } = this.query
      this.$lv2Store.setQueryParams(this.query)
      factories.many(this.query)
      this.$router.push({ name: 'searchMany', params: { locationQuery: regionName }, query })
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../styles/bulma-variables';
  .bg-image {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-image: url("https://theasiacollective.com/wp-content/uploads/2019/02/c1036f55-f497-4ba8-9834-597ade67d14c.jpg");
  }
</style>
