<template>
  <ul class="has-background-white">
    <li>
      <a
        :class="['list currencies-selector--item', value === it.abbreviation ? 'selected' : '']"
        v-for="it in supportedCurrencies"
        :key="it.abbreviation"
        @click.prevent.stop="clicked(it)">
        <div class="list-action currencies-selector--symbol">
          <span v-html="it.symbol"></span>
        </div>
        <div class="list-content currencies-selector--label">
          {{ it.currency }}
        </div>
      </a>
    </li>
  </ul>
</template>

<script>
import currencies from './currencies'
export default {
  name: 'CurrenciesSelector',
  props: {
    value: {
      type: String,
      default: process.env.VUE_APP_DEFAULT_CURRENCY
    }
  },
  data () {
    return {
      visibility: false
    }
  },
  computed: {
    supportedCurrencies () {
      // Can add filter here
      // [ { "currency": "Canada Dollar", "abbreviation": "CAD", "symbol": "CD&#36;" } ]
      return currencies
    }
  },
  methods: {
    clicked ({ abbreviation } = {}) {
      this.$emit('input', abbreviation)
      setTimeout(() => this.$emit('changed', abbreviation), 250)
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../../styles/bulma-variables';
  .currencies-selector--item {
    box-shadow: $list-border-shadow;
    &.selected {
      color: $dark;
      font-weight: 600;
      // @extend .selected-style;
      pointer-events: none;
    }
  }
  .currencies-selector--symbol {
    width: 3.5em;
    color: $link;
  }
  .currencies-selector--label {
    color: inherit;
  }
</style>
