import { render, staticRenderFns } from "./swipe.vue?vue&type=template&id=37c3c867&"
import script from "./swipe.vue?vue&type=script&lang=js&"
export * from "./swipe.vue?vue&type=script&lang=js&"
import style0 from "./swipe.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../Zumata/lv2/clients/asia-collective/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "swipe.vue"
export default component.exports