<template>
  <div class="pp-loading--parent">
    <div class="pp-loading--spinner">
      <svg class="pp-loading--circular" viewBox="25 25 50 50">
        <circle class="pp-loading--path" cx="50" cy="50" r="20" fill="none"/>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PpSpiner'
}
</script>

<style>

</style>
