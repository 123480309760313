<template>
  <svg xmlns="http://www.w3.org/2000/svg"
    :class="[sizeCss]"
    :width="xWidth"
    :height="xHeight"
    viewBox="0 0 18 18"
    :aria-labelledby="iconName"
    role="presentation"
  >
    <title :id="iconName" lang="en">{{iconName}} icon</title>
    <g :id="IconId" :fill="iconColor">
      <slot />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'PpIconBase',
  props: {
    size: {
      type: String,
      default: 'normal'
    },
    iconName: {
      type: String,
      default: ''
    },
    width: {
      type: [Number, String],
      default: '1em'
    },
    height: {
      type: [Number, String],
      default: '1em'
    },
    iconColor: {
      type: String,
      default: 'currentColor'
    }
  },
  computed: {
    sizeCss () {
      return `pp-icon-${this.size}`
    },
    xWidth () {
      switch (this.size) {
        case 'big':
          return '1.25em'
        default:
          return '1em'
      }
    },
    xHeight () {
      switch (this.size) {
        case 'big':
          return '1.25em'
        default:
          return '1em'
      }
    },
    IconId () {
      return this.iconName ? `pp-icon-${this.iconName}` : `pp-icon-svg`
    }
  }
}
</script>

<style scoped>
.pp-icon-normal {
  display: inline-block;
  /* vertical-align: baseline; */
  margin-bottom: -0.125em; /* yes, I'm that particular about formatting */
}
.pp-icon-big {
  display: inline-block;
  /* vertical-align: baseline; */
  margin-bottom: -0.3em; /* yes, I'm that particular about formatting */
}
</style>