<template>
  <header class="main-header">
    <div :class="layout">
  <nav class="nav-main" role="navigation" aria-label="main navigation">
    <div class="nav-left">
      <router-link
        class="nav-item nav-link nav-logo" :to="{ name: 'home' }">
        <img class="main-logo"
        src="@/assets/the-asia-colletctive-logo-light-tiny.png" alt="logo"
        />
      </router-link>
    </div>
    <div class="nav-right"
      v-cloak>
      <a class="nav-item nav-link"
        v-if="!$route.meta.hasCurrencyToggleHidden"
        @click="toggleCurrencyModal">
        {{ selectedCurrency }}
        <i class="el-icon-caret-bottom" />
      </a>
      <PpModal
        contentTransitionType="slideup"
        :isClickOutsideClose="true"
        v-model="currencyModal">
        <PpCard class="box is-flex">
          <h6 class="is-size-6">Select currency</h6>
          <CurrenciesSelector
            v-model="selectedCurrency"
            @changed="currencyChanged"/>
          <div slot="foot" class="list">
            <div class="list-content"></div>
            <el-button
              @click.stop="toggleCurrencyModal">
              Close
            </el-button>
          </div>
        </PpCard>
      </PpModal>
      <div class="nav-item nav-text"
        v-if="mx_isLoggedIn && $mq.tablet">
        <span>
          {{ mx_customer.displayName }}
        </span>
        <!-- <i class="el-icon-bell is-size-5" /> -->
      </div>
      <a class="nav-item nav-link has-text-link"
        :to="{ name: 'login' }"
        v-if="mx_isLoggedIn"
        @click.prevent="handleLogout">
        Logout
        <!-- <i class="el-icon-bell is-size-5" /> -->
      </a>
      <router-link class="nav-item nav-link has-text-link"
        :to="{ name: 'signup',  query: { reff } }"
        v-if="showLoginLink">
        Signup
      </router-link>
      <router-link class="nav-item nav-link has-text-link"
        :to="{ name: 'login', query: { reff } }"
        v-if="showLoginLink">
        Login
        <!-- <i class="el-icon-bell is-size-5" /> -->
      </router-link>
      <!-- <el-dropdown>
      <a
        class="nav-item nav-link is-hidden-mobile"
        >
        <span>Admin actions</span><i class="el-icon-arrow-down el-icon--right"></i>
      </a>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item
          :key="ln.match"
          v-for="ln in menus"
          v-if="$_jh_isAllowNavigate(ln.match)"
          >
          <router-link
            class="is-block nav-link"
            :to="ln.to">
            {{ ln.label }}
          </router-link>
        </el-dropdown-item>
      </el-dropdown-menu>
      </el-dropdown> -->
      <!-- <router-link
        class="nav-item nav-link"
        active-class="is-active">
        <span>Login</span>
      </router-link>
      <router-link
        class="nav-item nav-link is-hidden-mobile"
        active-class="is-active">
        <span>ddd</span>
      </router-link> -->
    </div>
  </nav>
    </div>
  </header>
</template>

<script>
import CurrenciesSelector from './currencies/CurrenciesSelector'
import EventBus from '../EventBus'

export default {
  components: {
    [CurrenciesSelector.name]: CurrenciesSelector
  },
  props: {
    routeMeta: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      currencyModal: false
    }
  },
  computed: {
    showLoginLink () {
      return !this.mx_isLoggedIn && !['login', 'signup', 'auth'].includes(this.$route.name)
    },
    reff () {
      return btoa(this.$route.fullPath)
    },
    layout () {
      return this.routeMeta.hasHeaderFullWidth ? '' : 'container is-desktop'
    },
    selectedCurrency: {
      get () {
        return this.lv2State.meta.currency || this.$route.query.currency || process.env.VUE_APP_DEFAULT_CURRENCY
      },
      set (ccy) {
        this.$lv2Store.setMetaAction({ ...this.lv2State.meta, currency: ccy })
      }
    }
  },
  methods: {
    toggleCurrencyModal () {
      this.currencyModal = !this.currencyModal
    },
    currencyChanged (ccy) {
      this.toggleCurrencyModal()
      EventBus.$emit('currency-changed', { currency: ccy })
    },
    handleLogout () {
      this.$lv2Store.setMetaAction({ customer: null, cusToken: null })
      location.reload()
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../styles/bulma-variables';

  .main-header {
    box-shadow: $list-border-shadow;
    background-color: $lw-header-backgroud-color;
    color: $white;
  }
  @include tablet {
    .container.is-desktop {
      .nav-logo {
        margin-left: $bleed * 2;
      }
      .nav-right {
        margin-right: $bleed * 2;
      }
    }
  }
  .main-logo {
    height: 65px;
  }
</style>
