<template>
  <div>
    <h1 class="title is-size-4-mobile is-marginless">
      <TrustYouRating class="is-inline-block is-pulled-right" style="margin: 6px 0 12px 6px" :condensed="true" size="s" v-bind="property.trustYou"/>
      <span style="letter-spacing: -0.5px; vertical-align: middle;">{{ property.name }}&nbsp;</span>
      <StarRatings class="is-inline-block is-size-6-mobile is-size-5-tablet" :ratings="property.starRating" style="vertical-align: middle;"/>
    </h1>
    <div class="has-line-height-1-125">
      <PropertyAddress
        class="is-size-7 is-inline"
        :params="$route.params"
        :query="$route.query"
        :mapLink="true"
        :location="property && property.location"/>
      <p class="is-size-7 is-italic is-inline" v-if="reviews.summary && reviews.summary.location">~ {{ reviews.summary && reviews.summary.location.text }}</p>
    </div>
    <PpGap />
    <div
      v-if="reviews.summary">
      <TrustYouBadgeWrapper
        class="is-pulled-right"
        :badgeList="reviews.badgeList">
        <template slot-scope="{ ranking }">
          <TrustYouRanked class="is-size-7-mobile" v-bind="ranking.badgeData" layout="vertical"/>
        </template>
      </TrustYouBadgeWrapper>
      <BadgeOutline
        :noMargin="true"
        class="is-size-7-mobile">
        <TrustYouRecommendation :reviewsDistribution="reviews.summary && reviews.summary.reviewsDistribution" />
      </BadgeOutline>
      <TrustYouPopularWith
        :noMargin="true"
        class="is-size-7-mobile"
        v-bind="reviews.summary && reviews.summary.popularWith" />
      <PpGap size="normal" />
      <!-- <TrustYouRating style="margin: 3px 0 3px 12px;" class="is-pulled-right" size="s" :condensed="true" v-bind="property.trustYou"/> -->
      <p class="has-text-dark is-size-6-mobile is-size-5-tablet">
        {{reviews.summary && reviews.summary.text }}
      </p>
      <PpGap size="large"
      v-if="reviews.hotelTypeList.length > 0"/>
      <TrustYouHotelType
        class="is-size-7-mobile"
        v-for="it in reviews.hotelTypeList"
        :key="it.categoryId"
        v-bind="it"
      />
    </div>
  </div>
</template>

<script>
import BadgeOutline from '@/components/BadgeOutline'
import PropertyAddress from '@/components/PropertyAddress'
import StarRatings from '@/components/StarRatings'
import TrustYouRecommendation from '@/components/TrustYouRecommendation'
import TrustYouRating from '@/components/TrustYouRating'
import TrustYouRanked from '@/components/TrustYouRanked'
import TrustYouBadgeWrapper from '@/components/TrustYouBadgeWrapper'
import TrustYouHotelType from '@/components/TrustYouHotelType'
import TrustYouPopularWith from '@/components/TrustYouPopularWith'

export default {
  name: 'SearchOneSummaryMobile',
  components: {
    [BadgeOutline.name]: BadgeOutline,
    [TrustYouRecommendation.name]: TrustYouRecommendation,
    [TrustYouRanked.name]: TrustYouRanked,
    [TrustYouRating.name]: TrustYouRating,
    [TrustYouPopularWith.name]: TrustYouPopularWith,
    [TrustYouBadgeWrapper.name]: TrustYouBadgeWrapper,
    [TrustYouHotelType.name]: TrustYouHotelType,
    [StarRatings.name]: StarRatings,
    [PropertyAddress.name]: PropertyAddress
  },
  props: {
    property: {
      type: Object,
      default () {
        return {}
      }
    },
    reviews: {
      type: Object,
      default () {
        return {}
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../styles/bulma-variables';

</style>
