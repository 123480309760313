<template>
    <div class="shadow-box filter-property-name">
      <div class="list is-gapless is-bottomless is-topless has-border-bottom">
        <div class="list-title list-content"><span class="has-text-weight-semibold">Filter name</span>
        </div>
        <div class="list-action">
          <el-button
            v-if="touched"
            type="text" class="has-text-grey-light" @click="handleClear">
            Clear
          </el-button>
        </div>
      </div>
      <div class="list is-gapless is-topless is-bottomless">
        <div class="list-content">
          <form @submit.prevent="handleSubmit">
            <el-input v-model="local"
              :size="$mq.mobile ? '' : 'small'"
              name="property_name_filter"
              placeholder="e.g: Hilton, Ibis ..."
              @blur="hanndleBlur">
              <el-button
                v-if="$mq.mobile"
                slot="append"
                native-type="submit">
                Go
              </el-button>
              <i slot="suffix"
                v-else
                :class="['el-input__icon el-icon-search', submited ? 'filter-property-name--submited' : '']"
                @click="handleSubmit"></i>
            </el-input>
          </form>
        </div>
      </div>
    </div>
</template>

<script>

export default {
  name: 'FiltersPropertyName',
  props: {
    value: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      local: this.value,
      submited: false
    }
  },
  computed: {
    touched () {
      return !!this.local
    }
  },
  watch: {
    'value': {
      immediate: true,
      handler (nV) {
        this.local = nV
      }
    }
  },
  methods: {
    reset () {
      this.local = ''
    },
    hanndleBlur () {
      // Something user just easre the filter without submit it, this to show the user the filter actually still active
      setTimeout(() => {
        if (this.value !== this.local) {
          this.local = this.value
        }
      }, 200)
    },
    handleClear () {
      this.$emit('input', '')
      this.reset()
      this.visibility = false
    },
    handleSubmit () {
      // console.log('handleSubmit', this.local)
      this.$emit('input', this.local)
      this.submited = true
      setTimeout(() => (this.submited = false), 200)
      // this.visibility = false
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../styles/bulma-variables';
  // .list-label {
  //   opacity: 0.68;
  // }

  .filter-property-name {
    padding: $bleed $bleed * 2;
    .el-input__icon.el-icon-search {
      cursor: pointer;
      &.filter-property-name--submited,
      &:active {
        color: $link;
        transform: scale(1.3);
      }
    }
  }
  .filter-property-name--touched {
    font-weight: 600;
    color: $text;
  }
  .filter-property-name--untouched {
    opacity: 0.68;
  }
</style>
