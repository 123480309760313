<template>
  <section class="section the-footer has-text-centered">
    <div class="container">
      <p class="has-text-centered">
        <img class="footer-logo" src="@/assets/the-asia-colletctive-logo-light-tiny.png" alt="logo" />
      </p>
      <div class="footer-socials">
        <a href="https://www.facebook.com/theasiacollective/" target="_blank">
          <i class="fab fa-facebook"></i>
        </a>
        <a href="https://twitter.com/asiacollective/" target="_blank">
          <i class="fab fa-twitter"></i>
        </a>
        <a href="https://www.instagram.com/theasia.collective/" target="_blank">
          <i class="fab fa-instagram"></i>
        </a>
      </div>
      <p>Copyright {{lv2State.app.title}} {{ year }}</p>
      <router-link
      :to="{ name: 'terms' }">
      Terms of uses
      </router-link>
    </div>
  </section>
</template>

<script>
const year = (new Date()).getFullYear()
export default {
  name: 'TheFooter',
  data () {
    return {
      year
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../styles/bulma-variables';
  .the-footer {
    background: $lw-footer-backgroud;
    color: $lw-footer-color;
    font-size: $size-7;
    .footer-logo {
      max-width: 300px;
      width: 100%;
    }
  }
  .footer-socials {
    font-size: 2.7em;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    a {
      margin: 1em;
    }
  }
</style>
