<template>
  <PpModal
    v-model="visibility"
    loadingMessage="Checking availibility"
    :isLoading="isLoading">
    <PpCard class="box is-flex" v-if="bookingPolicy.cancellationPolicy">
      <div class="list is-gapless">
        <div class="list-content">
          <h4 class="title is-4">Booking remarks</h4>
          <BookingPolicyPriceChange
            v-if="bookingPolicy.newPackage"
            v-bind="bookingPolicy"
            :selectedPckg="selectedPckg"
            />
          <div class="content"
            v-html="bookingPolicy.cancellationPolicy.remarks">
          </div>
          <CancellationPenalty
            :freeCancellationBefore="bookingPolicy.cancellationPolicy.freeCancellationBefore"
            />
        </div>
      </div>
      <template slot="foot" v-if="bookingPolicy.cancellationPolicy">
        <PpGap size="small"/>
        <div class="list is-gapless">
          <div class="list-content has-text-right">
            <el-button
              size="medium"
              type="plain"
              @click="handleClose">
              Close
            </el-button>
            <el-button
              type="success"
              size="medium"
              @click="handleNextStep(nextStep)">
              {{ reserveCallToActionLabel }} <span class="has-text-weight-semibold">{{ bookingPolicy.adjustedDisplayRate | currencyObj }}</span>
            </el-button>
          </div>
        </div>
        <div class="list is-gapless is-narrow">
          <div class="list-content has-text-right">
            <p class="is-size-7">
              Price included total taxes and fees {{ bookingPolicy.adjustedDisplayRate | taxesAndFees | currencyObj }} <span v-html="bookingPolicy.adjustedDisplayRate.taxesConfident === 0 ? '(estimated)' : ''" />.
            </p>
          </div>
        </div>
      </template>
    </PpCard>
    <PpCard class="box is-flex" v-else>
      <div class="list is-gapless">
        <div class="list-content">
          <h4 class="title is-4">Not bookabled package found</h4>
        </div>
      </div>
      <template slot="foot">
        <PpGap size="small"/>
        <div class="list is-gapless">
          <div class="list-content">
          </div>
          <div class="list-action">
            <el-button
              size="big"
              type="outline"
              @click="handleClose">
              Close
            </el-button>
          </div>
        </div>
      </template>
    </PpCard>
  </PpModal>
</template>

<script>
import lv2 from '../lv2Api'
import store from '../store'
import BookingPolicyPriceChange from '../components/BookingPolicyPriceChange'
import CancellationPenalty from '../components/CancellationPenalty'

const postCheckOut = async ({ body, query }) => {
  const { data } = await lv2.post('/checkout', body, { params: query })
  const { outlets, meta } = data
  store.setMetaAction(meta)
  return outlets
}

export default {
  name: 'BookingPolicyPopup',
  components: {
    [BookingPolicyPriceChange.name]: BookingPolicyPriceChange,
    [CancellationPenalty.name]: CancellationPenalty
  },
  props: {
    selectedPckg: {
      type: Object,
      required: true
    },
    searchId: {
      type: String,
      required: true
    },
    currency: {
      type: String,
      required: true
    },
    searchObject: {
      type: Object,
      required: true
    },
    property: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      isLoading: false,
      visibility: true,
      bookingPolicy: {},
      cancellationPolicy: undefined,
      nextStep: undefined
    }
  },
  computed: {
    reserveCallToActionLabel () {
      const nights = this.lv2State.meta.nights > 1 ? `${this.lv2State.meta.nights} nights` : '1 night'
      const rooms = this.lv2State.meta.roomCount > 1 ? `, ${this.lv2State.meta.roomCount} rooms` : ''
      // Reserve 1 night for
      // Reserve 1 night, 2 rooms for
      // Reserve 2 nights for
      // Reserve 2 nights, 2 rooms for
      return `Reserve ${nights}${rooms} for`
    }
  },
  created () {
    this.handleSelectedPackage(this.selectedPckg)
  },
  beforeDestroy () {

  },
  methods: {
    handleClose () {
      this.visibility = false
      this.$emit('close')
    },
    handleNextStep (nextStep) {
      this.$emit('next', nextStep)
    },
    async handleSelectedPackage (pckg) {
      this.isLoading = true
      const body = {
        locationQuery: this.$route.query.locationQuery,
        regionId: this.$route.query.regionId,
        property: {
          name: this.property.name
        },
        search: this.searchObject,
        token: pckg.token,
        adjustedDisplayRate: pckg.adjustedDisplayRate
      }
      const query = {
        currency: this.currency,
        searchId: this.searchId
      }
      try {
        const { availability, ckToken } = await postCheckOut({ body, query })
        const { package: newPckg = {} } = availability
        this.bookingPolicy = newPckg
        this.nextStep = { ckToken }
        this.isLoading = false
      } catch (err) {
        console.error(err)
        this.nextStep = {}
        this.isLoading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../styles/bulma-variables';
  .booking-policy-popup--spiner {
    padding: 100px 40px;
  }
</style>
