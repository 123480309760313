<template>
  <span :class="['ty--label', this.tier.css]">{{ this.tier.label }}</span>
</template>

<script>
import { tyGrades, tyToGrade } from '../common'

export default {
  name: 'TrustYouScoreLabel',
  props: {
    score: Number,
    tierKey: String // neg, pos, neu, pos2, pos3, gy
  },
  computed: {
    tier () {
      if (this.tierKey) {
        return tyGrades[this.tierKey]
      }
      return this.score && tyGrades[tyToGrade(this.score)]
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../styles/bulma-variables';
  /* ------------- ty--label -------------- */
  .ty--label .score {
    color: #666;
  }
  /* ------------- Negative ---------------- */
  .ty--label.neg {
    color: #f37159;
  }

  /* -------------- Neutral ---------------- */
  .ty--label.neu {
    color: #f9c015;
  }

  .ty--label.neu .value {
    background: #f9c015;
  }

  /* ------------- Positive ---------------- */
  .ty--label.pos {
    color: #119a11;
  }
</style>
