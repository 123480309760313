<template>
  <ul class="pp-breadcrumb">
    <li class="pp-breadcurmb--item"
      v-for="it in routes"
      :key="it.label">
      <slot v-bind:route="it">
        <span v-if="it.static">{{ it.label }}</span>
        <PpHistoryLink v-else v-bind="it" />
      </slot>
      <slot name="seperator" v-if="!it.hasSeperatorHidden">
        <PpIconBase
          class="pp-breadcurmb--seperator"
          iconName="arrow-right">
          <PpIconArrowRight />
        </PpIconBase>
      </slot>
    </li>
  </ul>
</template>

<script>
import PpIconBase from '../../icon-base/src/icon-base'
import PpIconArrowRight from '../../icon-base/icons/icon-arrow-right'
import PpHistoryLink from '../../history-link/src/history-link'

export default {
  name: 'PpBreadcrumb',
  components: {
    'PpIconArrowRight': PpIconArrowRight,
    [PpHistoryLink.name]: PpHistoryLink,
    [PpIconBase.name]: PpIconBase
  },
  props: {
    routes: {
      type: Array,
      default () {
        return []
      },
      required: true
    },
    size: {
      type: String,
      default: 'normal'
    }
  }
}
</script>
