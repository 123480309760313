<template>
  <div>
      <!-- width="220" -->
    <el-popover
      v-model="guestFormVisibility"
      placement="bottom"
      trigger="click"
      @show="showed"
      @hide="closed">
      <div class="guestWrapper">
        <div class="list is-gapless">
          <div class="list-content">
            <strong>{{ adultLabelLocal }}</strong>
          </div>
          <div class="list-action">
            <el-button type="text"
              @click="handleAdult(-1)"
              :disabled="adultCount === 1">
              <i class="is-size-4 el-icon-remove-outline"></i>
            </el-button>
            <el-button type="text"
              @click="handleAdult(1)">
              <i class="is-size-4 el-icon-circle-plus-outline"></i>
            </el-button>
          </div>
        </div>
        <div class="list is-gapless">
          <div class="list-content">
            <strong>{{ roomsLabelLocal }}</strong>
          </div>
          <div class="list-action">
            <el-button type="text"
              @click="handleRooms(-1)"
              :disabled="roomCount === 1">
              <i class="is-size-4 el-icon-remove-outline"></i>
            </el-button>
            <el-button type="text"
              @click="handleRooms(1)">
              <i class="is-size-4 el-icon-circle-plus-outline"></i>
            </el-button>
          </div>
        </div>
        <div class="list is-gapless has-border-bottom">
          <div class="list-content">
            <strong>{{ childrenAges.length }} children</strong>
          </div>
          <div class="list-action">
            <el-button type="text"
              @click="handleChildren(-1)"
              :disabled="childrenAges.length === 0">
              <i class="is-size-4 el-icon-remove-outline"></i>
            </el-button>
            <el-button type="text"
              @click="handleChildren(1)">
              <i class="is-size-4 el-icon-circle-plus-outline"></i>
            </el-button>
          </div>
        </div>
        <div :class="['list is-gapless', idx + 1 === childrenAges.length ? 'has-border-bottom': '']"
          v-for="(n, idx) in childrenAges"
          :key="'child_' + idx">
          <div class="list-content list-title">
            Age of child {{ idx + 1 }}
          </div>
          <div class="list-action">
            <el-select class="child-age"
              size="small"
              v-model="childrenAges[idx]"
              placeholder="age">
              <el-option
                v-for="age in childMaxAge"
                :key="'childAge_' + age"
                :label="age"
                :value="age">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="list">
          <div class="list-content"></div>
          <div class="list-action">
            <el-button @click="handleApply" type="text">
              APPLY
            </el-button>
          </div>
        </div>
      </div>
      <el-input slot="reference"
        :readonly="true"
        :value="guests" />
    </el-popover>
  </div>
</template>

<script>
const defaultRoomCount = 1
const defaultAdultCount = 2

export default {
  name: 'SearchGuest',
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      guestFormVisibility: false,
      roomCount: defaultRoomCount,
      adultCount: defaultAdultCount,
      childrenAges: [],
      childMaxAge: 17
    }
  },
  computed: {
    roomsLabel () {
      return this.value.roomCount > 1 ? `${this.value.roomCount} rooms` : '1 room'
    },
    adultLabel () {
      return this.value.adultCount > 1 ? `${this.value.adultCount} adults` : '1 adult'
    },
    roomsLabelLocal () {
      return this.roomCount > 1 ? `${this.roomCount} rooms` : '1 room'
    },
    adultLabelLocal () {
      return this.adultCount > 1 ? `${this.adultCount} adults` : '1 adult'
    },
    childrenLabel () {
      return this.childrenParser.length > 0 ? `, ${this.childrenParser.length} children` : ', 0 children'
    },
    childrenParser () {
      return this.value.children ? this.value.children.split(',') : []
    },
    guests () {
      return `${this.adultLabel}, ${this.roomsLabel}${this.childrenLabel}`
    }
  },
  watch: {
    'value': {
      immediate: true,
      handler (nV) {
        this.roomCount = isNaN(nV.roomCount) ? defaultRoomCount : parseInt(nV.roomCount, 10)
        this.adultCount = isNaN(nV.adultCount) ? defaultAdultCount : parseInt(nV.adultCount, 10)
        this.childrenAges = nV.children ? nV.children.split(',') : []
      }
    }
  },
  methods: {
    showed () {
      this.reset()
      this.$emit('opened')
    },
    closed () {
      this.$emit('closed')
    },
    reset () {
      this.roomCount = isNaN(this.value.roomCount) ? defaultRoomCount : parseInt(this.value.roomCount, 10)
      this.adultCount = isNaN(this.value.adultCount) ? defaultAdultCount : parseInt(this.value.adultCount, 10)
      this.childrenAges = this.value.children ? this.value.children.split(',') : []
    },
    handleRooms (val) {
      this.roomCount = this.roomCount + val
      if (this.roomCount > this.adultCount) {
        this.adultCount = this.roomCount
      }
    },
    handleAdult (val) {
      this.adultCount = this.adultCount + val
      if (this.adultCount < this.roomCount) {
        this.roomCount = this.adultCount
      }
    },
    handleChildren (val) {
      if (val > 0) {
        this.childrenAges.push(6)
      } else {
        this.childrenAges.pop()
      }
    },
    handleApply () {
      this.$emit('input', {
        adultCount: this.adultCount,
        roomCount: this.roomCount,
        children: this.childrenAges.join(',')
      })
      this.guestFormVisibility = false
    }
  }
}
</script>

<style lang="scss" scoped>
  .guestWrapper {
    .el-button {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
  .child-age {
    width: 70px;
  }
</style>
