<template>
  <header class="kkkk">
    <form class="columns is-gapless kkk is-mobile is-multiline" @submit.prevent="handleSearch">
      <div class="column col-prepend"
        v-if="$slots.prepend">
        <slot name="prepend"/>
      </div>
      <div class="column is-narrow is-12-mobile"
        style="min-width: 260px;">
        <DateRange v-model="dateRangeModel"
          :layoutMode="$mq.all"
          style="width: 100%;min-width: 270px;"/>
        <div class="is-size-7 has-text-danger" v-show="dateRangeError">{{dateRangeError}}</div>
      </div>
      <div class="column is-narrow" style="width: 250px;">
        <SearchGuest v-model="guestModel"
          @opened="preventSearch"
          @closed="enableSearch"/>
      </div>
      <div class="column is-narrow col-action">
        <el-button type="primary"
          :disabled="isSearchDisabled"
          native-type="submit">Search</el-button>
      </div>
    </form>
  </header>
</template>

<script>
import LocationSearch from './LocationSearch'
import DateRange from './DateRange'
import SearchGuest from './SearchGuest'

export default {
  name: 'SearchBarOne',
  components: {
    [LocationSearch.name]: LocationSearch,
    [DateRange.name]: DateRange,
    [SearchGuest.name]: SearchGuest
  },
  data () {
    return {
      isSearchDisabled: false,
      dateRange: [],
      dateRangeModel: {
        checkInDate: this.$route.query.checkInDate,
        checkOutDate: this.$route.query.checkOutDate
      },
      guestModel: {
        adultCount: this.$route.query.adultCount || 1,
        roomCount: this.$route.query.roomCount || 1,
        children: this.$route.query.children
      },
      meta: {
        placeId: undefined,
        queryString: '',
        starRatingPopover: false,
        pricesPopover: false
      },
      autosuggestResults: {
        region: [],
        address: [],
        airport: [],
        trainStation: []
      },
      locationError: '',
      dateRangeError: ''
    }
  },
  computed: {
    searchManyQuery () {
      return {
        checkInDate: this.dateRangeModel.checkInDate,
        checkOutDate: this.dateRangeModel.checkOutDate,
        roomCount: this.guestModel.roomCount,
        adultCount: this.guestModel.adultCount,
        children: this.guestModel.children || undefined
      }
    }
  },
  async beforeRouteUpdate (to, from, next) {
    next()
  },
  watch: {
    'dateRangeModel': {
      deep: true,
      handler (nV) {
        this.dateRangeValidator(nV)
      }
    }
  },
  methods: {
    dateRangeValidator (val) {
      if (val == null) {
        this.dateRangeError = 'Check-in date is required'
        return false
      }
      if (val.checkOutDate == null || val.checkOutDate === '') {
        this.dateRangeError = 'Check-out date is required'
        return false
      }
      this.dateRangeError = ''
      return true
    },
    getQuery (more) {
      return {
        ...(this.searchManyQuery),
        currency: this.lv2State.meta.currency,
        searchId: this.$lv2_newSearchId(),
        ...more
      }
    },
    handleSearch () {
      const query = this.getQuery()
      if (!this.dateRangeValidator({ checkInDate: query.checkInDate, checkOutDate: query.checkOutDate })) return

      this.$emit('search', query)
    },
    preventSearch () {
      this.isSearchDisabled = true
    },
    enableSearch () {
      this.isSearchDisabled = false
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../styles/bulma-variables';
  .col-prepend {
    flex: 1;
    align-self: center;
  }
  @include tablet {
    .kkk {
      justify-content: flex-end;
    }
  }
  .kkkk .kkk > .column {
    padding: 0 $bleed !important;
    &:last-child {
      padding-right: 0 !important;
    }
    @include mobile {
      padding-bottom: $bleed !important;
      padding-right: 0 !important;
      &:not(.col-action) {
        padding-left: 0 !important;
      }
    }
  }
  .guestWrapper {
    .el-button {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
  .child-age {
    width: 70px;
  }
  .block-checkbox-list /deep/ .el-checkbox {
    display: block;
  }
  // .el-form {
  //   .el-form-item {
  //     margin-right: 0;
  //   }
  // }
  // .el-form-item /deep/ .el-form-item__content {
  //   width: 100%;
  // }
</style>

<style lang="scss">
  .el-autocomplete-suggestion li {
    padding: 0;
  }
</style>
