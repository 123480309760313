<template>
    <div class="shadow-box filter-price">
      <div class="list is-gapless is-bottomless is-topless has-border-bottom">
        <div class="list-title list-content"><span class="has-text-weight-semibold">Nightly avg</span>
        </div>
        <div class="list-action">
          <el-button
            v-if="touched"
            type="text" class="has-text-grey-light" @click="handleClear">
            Clear
          </el-button>
          <!-- Nightly avg ({{ ccySymbol }}) -->
        </div>
      </div>
      <div class="list is-gapless is-topless is-bottomless">
        <div class="list-content" style="margin: 0 6px;">
          <el-slider
            v-model="priceRange"
            range
            :min="min"
            :max="max"
            :show-tooltip="false"
            @change="handleChanged">
          </el-slider>
        </div>
      </div>
      <div class="list is-gapless is-topless is-narrow">
        <div class="list-pre ">
          <!-- {{ min }} -->
          <div class="list-label is-italic">Min</div>
          <div :class="[touched ? 'filter-price--touched' : 'filter-price--untouched']">{{ minStrLocal }}</div>
        </div>
        <div class="list-content has-text-centered has-text-weight-semibold has-text-dark">
          <!-- {{ priceRange[0] }} – {{ priceRange[1] }} -->
        </div>
        <div class="list-action">
          <!-- {{ max }}+ -->
          <div class="list-label has-text-right is-italic">Max</div>
          <div :class="[touched ? 'filter-price--touched' : 'filter-price--untouched']">{{ maxStrLocal }}</div>
        </div>
      </div>
      <!-- <PpGap size="big" />
      <div class="list is-gapless">
        <div class="list-content">
          <el-button type="text" class="has-text-danger" @click="handleClear">
            Clear
          </el-button>
        </div>
        <div class="list-action">
          <el-button type="primary" @click="handleApply">
            Apply
          </el-button>
        </div>
      </div> -->
    </div>
</template>

<script>

export default {
  name: 'FiltersPriceRange',
  props: {
    value: {
      type: Array,
      required: true
    },
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 230
    },
    currency: {
      type: String
    }
  },
  data () {
    return {
      priceRange: [this.min, this.max],
      visibility: false,
      local: this.value
    }
  },
  computed: {
    ccySymbol () {
      const kStr = this.toCurrency(99)
      return kStr.replace(/99/, '').trim()
    },
    touched () {
      return this.priceRange[0] !== this.min || this.priceRange[1] !== this.max
    },
    // minStr () {
    //   return this.value[0] === this.min ? this.min : this.toCurrency(this.value[0])
    // },
    minStrLocal () {
      return this.priceRange[0] === this.min ? this.min : this.toCurrency(this.priceRange[0])
    },
    infinityStrLocal () {
      return this.priceRange[1] === Infinity ? `${this.toCurrency(this.max)}+` : `${this.toCurrency(this.priceRange[1])}+`
    },
    // infinityStr () {
    //   return this.value[1] === Infinity ? `${this.toCurrency(this.max)}+` : `${this.toCurrency(this.value[1])}+`
    // },
    maxStrLocal () {
      return this.priceRange[1] === this.max || this.priceRange[1] === Infinity ? this.infinityStrLocal
        : this.toCurrency(this.priceRange[1])
    }
    // maxStr () {
    //   return this.priceRange[1] === this.max || this.value[1] === Infinity ? this.infinityStr :
    //   this.toCurrency(this.value[1])
    // },
    // localStr () {
    //   return isNaN(this.value[0]) ? 'Price ANY' : this.minStr + ' – ' + this.maxStr
    //   // return this.localSort.icon
    // }
  },
  watch: {
    'value': {
      immediate: true,
      handler (nV) {
        if (nV.length === 2) {
          this.priceRange = nV
        }
      }
    }
  },
  methods: {
    toCurrency (val) {
      return this.$root.$options.filters.currency(val, { currency: this.currency, factionDigits: 0 })
    },
    reset () {
      this.priceRange = [this.min, this.max]
      // this.priceRange = [this.value[0] || this.min, this.value[1] || this.max]
    },
    handleClear () {
      this.$emit('input', [])
      this.reset()
      this.visibility = false
    },
    handleChanged () {
      const min = this.priceRange[0]
      const max = this.priceRange[1] === this.max ? Infinity : this.priceRange[1]
      const ex = [min, max]
      if (ex[0] === this.min && ex[1] === Infinity) {
        this.$emit('input', [])
      } else {
        this.$emit('input', ex)
      }
      this.visibility = false
    },
    opened () {
      const min = this.value[0] || this.min
      const max = this.value[1] === Infinity ? this.max : this.value[1] || this.max
      this.priceRange = [min, max]
    },
    closed () {
      this.reset()
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../styles/bulma-variables';
  .list-label {
    opacity: 0.68;
  }
  .filter-price {
    padding: $bleed $bleed * 2;
  }
  .filter-price--touched {
    font-weight: 600;
    color: $text;
  }
  .filter-price--untouched {
    opacity: 0.68;
  }
</style>
