<template>
  <div :class="['ranked has-text-centered', layout]"
    v-if="popularity < 15 && popularity > 0">
    <span class="lab">RANKED</span>
    <span v-if="layout === 'horizontal'">&nbsp;</span>
    <span class="val">TOP {{ popularity / 100 | pct({ factionDigits: 0 }) }}</span>
  </div>
</template>

<script>
export default {
  name: 'TrustYouRanked',
  props: {
    popularity: {
      type: Number,
      default: 0
    },
    layout: {
      type: String,
      default: 'horizontal' // vertical
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../styles/bulma-variables';
  .ranked {
    display: inline-block;
    // background-color: #e86707;
    background-color: $pos-color;
    color: #fff;
    padding: $bleed $bleed * 3;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 1.2;
    text-shadow: 0 1px 2px rgba(10,10,10,.8);
    border-radius: $bleed / 2;
    &.vertical .lab {
      display: block;
    }
    &.vertical .val {
      display: block;
    }
  }
</style>
