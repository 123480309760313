<template>
  <section class="many-filters">
    <FiltersPriceRange v-model="priceRange"
      :currency="lv2State.meta.currency" />
    <PpGap />
    <FiltersPropertyName v-model="propertyName" />
    <PpGap />
    <FiltersTrustYouRange v-model="tyRange"
      :layoutMode="layoutMode"/>
    <PpGap />
    <FiltersStarRange v-model="starRange"
      :layoutMode="layoutMode"/>
  </section>
</template>

<script>
import FiltersPriceRange from './FiltersPriceRange'
import FiltersTrustYouRange from './FiltersTrustYouRange'
import FiltersStarRating from './FiltersStarRating'
import FiltersPropertyName from './FiltersPropertyName'

export default {
  name: 'SearchManyFilters',
  components: {
    [FiltersPriceRange.name]: FiltersPriceRange,
    [FiltersTrustYouRange.name]: FiltersTrustYouRange,
    [FiltersStarRating.name]: FiltersStarRating,
    [FiltersPropertyName.name]: FiltersPropertyName
  },
  data () {
    return {
      timer: undefined
    }
  },
  props: {
    layoutMode: {
      type: Array,
      default () {
        return []
      }
    }
  },
  computed: {
    propertyName: {
      get () {
        return this.$route.query.propertyName || ''
      },
      set (val) {
        this.$emit('search', {
          page: 1,
          propertyName: val
        })
      }
    },
    priceRange: {
      get () {
        const pr = this.$route.query.priceRange
        if (pr == null) return []
        const o = pr.split(',').map(e => Number(e))
        if (o.length !== 2) return []
        return o
      },
      set (val) {
        this.$emit('search', {
          page: 1,
          priceRange: val.join(',')
        })
      }
    },
    tyRange: {
      get () {
        const ty = this.$route.query.tyRange
        if (!ty) return {}
        return ty.split(',').reduce((pV, cV) => {
          pV[cV] = true
          return pV
        }, {})
      },
      set (val) {
        const actives = Object.entries(val).filter(([key, boo]) => boo)
        this.$emit('search', {
          page: 1,
          tyRange: actives.map(([key, boo]) => key).join(',')
        })
      }
    },
    starRange: {
      get () {
        const ty = this.$route.query.starRange
        if (!ty) return {}
        return ty.split(',').reduce((pV, cV) => {
          pV[cV] = true
          return pV
        }, {})
      },
      set (val) {
        const actives = Object.entries(val).filter(([key, boo]) => boo)
        this.$emit('search', {
          page: 1,
          starRange: actives.map(([key, boo]) => key).join(',')
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../styles/bulma-variables';

  .many-filters {
    // padding: $bleed;
  }
</style>
