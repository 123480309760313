import axios from 'axios'
import store from './store'

export const CancelToken = axios.CancelToken
export const isCancel = axios.isCancel
export function axiosErrorHandler (error) {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    console.error('Response error', error.response.data)
    // console.log(error.response.data)
    // console.log(error.response.status)
    // console.log(error.response.headers)
    return error.response.data
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    console.error('Request error', error.request)
    // console.log(error.request)
    return error.request
  } else {
    // Something happened in setting up the request that triggered an Error
    console.log('Error', error)
  }
}
const inst = axios.create({
  baseURL: `${process.env.VUE_APP_LV2_API_HOST}/web`,
  headers: {
    [process.env.VUE_APP_ACCEES_KEY_HEADER]: process.env.VUE_APP_ACCESS_KEY
  },
  timeout: 30 * 1000,
  maxRedirects: 1
})

/**
 * Attach customer token on all api call
 */
inst.interceptors.request.use(function (config) {
  config.headers = {
    ...config.headers,
    ...store.getAuthorizationHeader(),
    'lv-app-aff': store.state.affiliate.code
  }
  return config
})

export default inst
