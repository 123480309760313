<template>
  <router-link
    :to="{ name: 'searchMap', query ,
      params: {
        locationQuery: params.locationQuery || query.locationQuery
      } }"
    v-if="mapLink">
    {{ location.address }} (view in map)
  </router-link>
  <span v-else>
    {{ location.address }}
  </span>
</template>

<script>
export default {
  name: 'PropertyAddress',
  props: {
    params: {
      type: Object,
      default () {
        return {}
      }
    },
    query: {
      type: Object,
      default () {
        return {}
      }
    },
    mapLink: Boolean,
    propertyCode: String,
    propertyName: String,
    location: {
      type: Object,
      default () {
        return {}
      }
    }
  }
}
</script>

<style>

</style>
