<template>
  <p
    v-if="freeCancellationBefore"
    class="has-text-success is-flex" style="align-items: center;">
    <i class="el-icon-check icon-size" />&nbsp;
    <span class="">Refundable before</span>&nbsp;
    <span class="has-text-black-ter">{{ freeCancellationBefore | date({
      timeZone: 'UTC',
      weekday: 'short',
      month: 'short',
      day: 'numeric',
      }) }} </span>
  </p>
  <p
    v-else
    class="has-text-danger is-flex" style="align-items: center;">
    <i class="el-icon-warning icon-size" />&nbsp;
    <span class="">This is a non-refundable room.</span>
  </p>
</template>

<script>
export default {
  name: 'CancellationParse',
  props: {
    freeCancellationBefore: {
      type: String | undefined,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
  .icon-size {
    font-size: 1.25em;
  }
</style>
