import {
  Pagination,
  Autocomplete,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Input,
  Radio,
  RadioButton,
  Checkbox,
  CheckboxGroup,
  CheckboxButton,
  Slider,
  Switch,
  Select,
  Option,
  Button,
  ButtonGroup,
  Popover,
  Tooltip,
  Form,
  FormItem,
  Icon,
  Loading
} from 'element-ui'
import lang from 'element-ui/lib/locale/lang/en'
import locale from 'element-ui/lib/locale'
import '../styles/element-ui/index.scss'

locale.use(lang)

const install = function (Vue) {
  Vue.use(Pagination)
  Vue.use(Autocomplete)
  Vue.use(Dropdown)
  Vue.use(DropdownMenu)
  Vue.use(DropdownItem)
  Vue.use(Input)
  Vue.use(Radio)
  Vue.use(RadioButton)
  Vue.use(Checkbox)
  Vue.use(CheckboxGroup)
  Vue.use(CheckboxButton)
  Vue.use(Slider)
  Vue.use(Switch)
  Vue.use(Select)
  Vue.use(Option)
  Vue.use(Button)
  Vue.use(ButtonGroup)
  Vue.use(Popover)
  Vue.use(Tooltip)
  Vue.use(Form)
  Vue.use(FormItem)
  Vue.use(Icon)
  Vue.use(Loading.directive)

  Vue.prototype.$loading = Loading.service
}

export default {
  install
}
