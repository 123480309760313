<template>
  <BadgeOutline
    v-if="tripType && !layout"
    class="popular-badge"
    :noMargin="noMargin">
    <i :class="['ty-icon', icon]"></i>
    <span class="ty-text" v-html="text"></span>
  </BadgeOutline>
  <div v-else>
    <i :class="['ty-icon', icon]"></i>
    <span class="ty-text" v-html="text"></span>
  </div>
</template>

<script>
import BadgeOutline from './BadgeOutline'

export default {
  name: 'TrustYouPopularWith',
  components: {
    [BadgeOutline.name]: BadgeOutline
  },
  props: {
    tripType: String,
    text: String,
    noMargin: {
      type: Boolean,
      default: false
    },
    layout: {
      type: String,
      default: ''
    }
  },
  computed: {
    icon () {
      let typ
      switch (this.tripType) {
        case 'friends':
          typ = 'group'
          break
        default:
          typ = this.tripType
          break
      }
      return `ty-icon-${typ}`
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../styles/bulma-variables';
  .ty-icon {
    margin-right: $bleed;
    color: #e88e15;
    font-size: 1.2em;
  }
  .ty-icon, .ty-text {
    vertical-align: middle;
    // display: inline-block;
    // color: $dark;
  }
  .popular-badge {
    background-color: rgba(230,162,60,.1);
  }
</style>
