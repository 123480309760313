<template>
  <li
    v-if="ignoredAvailability || availability"
    :class="['amenities--label', availableCss]">
    <i :class="['amenities--icon', iconCss]"></i>&nbsp;
    <span>{{ label }}</span>
  </li>
</template>

<script>
export default {
  name: 'AmenitiesLabel',
  props: {
    label: String,
    availability: Boolean,
    ignoredAvailability: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    iconCss () {
      return this.availability ? 'el-icon-circle-check' : 'el-icon-circle-close'
    },
    availableCss () {
      return this.availability ? 'amenities--available' : ''
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../styles/bulma-variables';

  .amenities--label {
    color: $grey-light;
    &.amenities--available {
      color: $text;
      .amenities--icon {
        color: $success;
      }
    }
    .amenities--icon {
      color: $grey-light;
    }
  }
</style>
