<template>
  <div class="property-list">
    <div class="fixed-height"
      v-if="isLoading">
      <div class="property-list--spiner">
        <PpSpiner/>
      </div>
    </div>
    <div class="fixed-height"
      v-else>
      <router-link
        :id="'pc'+ it.property.propertyCode"
        :class="['results-item is-light', { 'is-selecting': lv2State.searchMany.selected === it.property.propertyCode }]"
        v-for="it in results"
        :to="toLink(it.property)"
        :key="it.property.propertyCode"
        @click.native="handleSelected(it)"
        @mouseenter.native="handleHover(it)"
        @mouseleave.native.self="handleOut(it)"
        :target="target">
        <component
          :is="propertyComponent"
          :layoutMode="$mq.all"
          :layout="layout"
          :imageSize="imageSize"
          v-bind="it" :nights="nights"
          @heroImageClicked="handleHeroImageClicked"/>
      </router-link>
      <div v-if="!isLoading && results.length === 0" class="list" style="height: 200px;">
        <EmptyState
          class="has-text-centered list-content"
          v-if="results.length === 0"
          :isLoading="isLoading"
          message="Sorry! We couldn't find any properties for your search." />
        <!-- <div class="list-content has-text-centered">
          Sorry! We cloudn't find any properties for your search.
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PropertyList',
  components: {
    'Property': () => import(/* webpackChunkName: "tablet" */ './Property'),
    'PropertyMobile': () => import(/* webpackChunkName: "mobile" */ './PropertyMobile')
  },
  props: {
    availability: Object,
    nights: Number,
    isLoading: Boolean,
    imageSize: String,
    layout: String,
    layoutMode: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      timer: undefined
    }
  },
  computed: {
    target () {
      return this.layoutMode.includes('mobile') ? '_self' : '_blank'
    },
    propertyComponent () {
      return this.layoutMode.includes('mobile') ? 'PropertyMobile' : 'Property'
    },
    results () {
      return this.availability.results || []
    },
    searchOneProperty () {
      return this.lv2State.searchOne.property ? this.lv2State.searchOne.property : null
    },
    searchOnePackage () {
      return this.lv2State.searchOne.availability ? this.lv2State.searchOne.availability.packages[0] : null
    },
    searchOneReviews () {
      return this.lv2State.searchOne.reviews ? this.lv2State.searchOne.reviews : null
    }
  },
  methods: {
    toLink (property) {
      return { name: 'property',
        params: {
          name: property.name
        },
        query: {
          ...this.$route.query,
          propertyCode: property.propertyCode,
          locationQuery: this.$route.params.locationQuery
        }
      }
    },
    handleStop () {
      // Keep this. to prevent click event bubble up
    },
    handleOut () {
      clearTimeout(this.timer)
    },
    handleHover (val) {
      this.timer = setTimeout(() => {
        this.$lv2Store.setSearchMany({ selected: val.property.propertyCode })
      }, 500)
      // const CEvt = document.createEvent('CustomEvent')
      // CEvt.initCustomEvent('hover-item', false, false, { propertyCode: val.property.propertyCode })
      // body.dispatchEvent(CEvt)
    },
    handleSelected (val) {
      this.$emit('selected', val)
    },
    handleHeroImageClicked (val) {
      this.$emit('heroImageClicked', val)
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../styles/bulma-variables';
  .property-list {
    padding: $bleed;
  }
  .property-list--spiner {
    padding: 100px 40px;
  }
  .results-item {
    // overflow: hidden;
    border-radius: $bleed;
    background-color: #fff;
    display: block;
    transition: box-shadow 80ms $selected-transition;
    // box-shadow: 0 0 1px  rgba(60,64,67,0.302), 0 0px 0px 10px rgba(10, 10, 10, 0);
    box-shadow: $card-shadow-simple;
    transform: perspective(0px);
    &:active {
      // transform: perspective(0px) translateY(2px);
    }
  }
  .results-item:not(:last-child) {
    margin-bottom: $bleed * 2;
  }
  .results-item.is-selecting {
    // z-index: 1;
    // border: none !important;
    // background-color: $white-bis;
    // box-shadow: 0 1px 3px 0 rgba(60,64,67,0.302), 0 4px 8px 3px rgba(60,64,67,0.149);
    // box-shadow: 0 1px 5px rgba(60,64,67,0.302), 0 10px 50px 10px rgba(60,64,67,0.149);
    box-shadow: $selected-box-shadow;
    // transform: perspective(0px);
  }
  .fixed-height {
    @include tablet {
      min-height: 750px;
    }
    @include mobile {
      min-height: 450px;
    }
  }

</style>
