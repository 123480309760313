<template>
  <div
    class="pp-swipe"
    ref="ele">
    <slot></slot>
  </div>
</template>

<script>
function getGesturePointFromEvent (evt) {
  var point = {}

  if (evt.targetTouches) {
    // Prefer Touch Events
    point.x = evt.targetTouches[0].clientX
    point.y = evt.targetTouches[0].clientY
  } else {
    // Either Mouse event or Pointer Event
    point.x = evt.clientX
    point.y = evt.clientY
  }

  return point
}

const STATE_DEFAULT = 1
const STATE_BOTTOM_SIDE = 2
const STATE_TOP_SIDE = 3

export default {
  name: 'PpSwipe',
  data () {
    return {
      initialTouchPos: null,
      lastTouchPos: null,
      currentYPosition: 0,
      currentState: STATE_DEFAULT,
      rafPending: false,
      itemWidth: 0,
      itemHeight: 0,
      handleSize: 10
    }
  },
  computed: {
    slopValue () {
      return this.itemHeight * (1 / 4)
    }
  },
  mounted () {
    setTimeout(() => {
      this.itemWidth = this.$refs.ele.clientWidth
      this.itemHeight = this.$refs.ele.clientHeight
    }, 100)
    if (false) {
      // Add Pointer Event Listener
      this.$refs.ele.addEventListener('pointerdown', this.handleGestureStart, false)
      this.$refs.ele.addEventListener('pointermove', this.handleGestureMove, false)
      this.$refs.ele.addEventListener('pointerup', this.handleGestureEnd, false)
      this.$refs.ele.addEventListener('pointercancel', this.handleGestureEnd, false)
    } else {
      // Add Touch Listener
      this.$refs.ele.addEventListener('touchstart', this.handleGestureStart, false)
      this.$refs.ele.addEventListener('touchmove', this.handleGestureMove, false)
      this.$refs.ele.addEventListener('touchend', this.handleGestureEnd, false)
      this.$refs.ele.addEventListener('touchcancel', this.handleGestureEnd, false)

      // Add Mouse Listener
      this.$refs.ele.addEventListener('mousedown', this.handleGestureStart, false)
    }
  },
  methods: {
    handleGestureStart (evt) {
      // evt.preventDefault()
      if (evt.touches && evt.touches.length > 1) {
        return
      }

      // Add the move and end listeners
      if (false) {
        evt.target.setPointerCapture(evt.pointerId)
      } else {
        // Add Mouse Listeners
        document.addEventListener('mousemove', this.handleGestureMove, true)
        document.addEventListener('mouseup', this.handleGestureEnd, true)
      }

      this.initialTouchPos = getGesturePointFromEvent(evt)

      this.$refs.ele.style.transition = 'initial'
    },
    handleGestureEnd (evt) {
      // evt.preventDefault()
      if (evt.touches && evt.touches.length > 0) {
        return
      }

      this.rafPending = false

      // Remove Event Listeners
      if (false) {
        evt.target.releasePointerCapture(evt.pointerId)
      } else {
        // Remove Mouse Listeners
        document.removeEventListener('mousemove', this.handleGestureMove, true)
        document.removeEventListener('mouseup', this.handleGestureEnd, true)
      }

      if (this.lastTouchPos != null) {
        this.updateSwipeRestPosition()
      }

      this.initialTouchPos = null
    },
    handleGestureMove (evt) {
      // evt.preventDefault()

      if (!this.initialTouchPos) {
        return
      }

      this.lastTouchPos = getGesturePointFromEvent(evt)

      if (this.rafPending) {
        return
      }

      this.rafPending = true

      window.requestAnimationFrame(this.onAnimFrame)
    },
    onAnimFrame () {
      if (!this.rafPending) {
        return
      }

      var differenceInY = this.initialTouchPos.y - this.lastTouchPos.y
      var newYTransform = (this.currentYPosition - differenceInY) + 'px'
      var transformStyle = `translateY(${newYTransform})`
      this.$refs.ele.style.webkitTransform = transformStyle
      this.$refs.ele.style.MozTransform = transformStyle
      this.$refs.ele.style.msTransform = transformStyle
      this.$refs.ele.style.transform = transformStyle

      this.rafPending = false
    },
    updateSwipeRestPosition () {
      var differenceInY = this.initialTouchPos.y - this.lastTouchPos.y
      this.currentYPosition = this.currentYPosition - differenceInY

      // Go to the default state and change
      var newState = STATE_DEFAULT

      // Check if we need to change state to below or up based on slop value
      if (Math.abs(differenceInY) > this.slopValue) {
        if (this.currentState === STATE_DEFAULT) {
          if (differenceInY > 0) {
            newState = STATE_TOP_SIDE
          } else {
            newState = STATE_BOTTOM_SIDE
          }
        } else {
          newState = STATE_DEFAULT
        }
      } else {
        newState = this.currentState
      }

      this.changeState(newState)

      this.$refs.ele.style.transition = 'transform 150ms ease-out'
    },
    changeState (newState) {
      var transformStyle
      switch (newState) {
        case STATE_DEFAULT:
          this.currentYPosition = 0
          break
        case STATE_BOTTOM_SIDE:
          this.currentYPosition = 1000
          this.$emit('outOfBound')
          // this.currentYPosition = this.itemHeight - this.handleSize
          // this.currentYPosition = -(this.itemHeight - this.handleSize)
          break
        case STATE_TOP_SIDE:
          this.currentYPosition = -1000
          this.$emit('outOfBound')
          // this.currentYPosition = -(this.itemHeight - this.handleSize)
          break
      }

      transformStyle = 'translateY(' + this.currentYPosition + 'px)'

      this.$refs.ele.style.webkitTransform = transformStyle
      this.$refs.ele.style.MozTransform = transformStyle
      this.$refs.ele.style.msTransform = transformStyle
      this.$refs.ele.style.transform = transformStyle

      this.currentState = newState
    }
  }
}
</script>

<style lang="scss">
  .pp-swipe {
    touch-action: none;
    backface-visibility: hidden;
  }
</style>
