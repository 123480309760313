import { render, staticRenderFns } from "./CurrenciesSelector.vue?vue&type=template&id=c2973638&scoped=true&"
import script from "./CurrenciesSelector.vue?vue&type=script&lang=js&"
export * from "./CurrenciesSelector.vue?vue&type=script&lang=js&"
import style0 from "./CurrenciesSelector.vue?vue&type=style&index=0&id=c2973638&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c2973638",
  null
  
)

component.options.__file = "CurrenciesSelector.vue"
export default component.exports