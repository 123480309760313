import { render, staticRenderFns } from "./FiltersStarRating.vue?vue&type=template&id=b2421326&scoped=true&"
import script from "./FiltersStarRating.vue?vue&type=script&lang=js&"
export * from "./FiltersStarRating.vue?vue&type=script&lang=js&"
import style0 from "./FiltersStarRating.vue?vue&type=style&index=0&id=b2421326&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b2421326",
  null
  
)

component.options.__file = "FiltersStarRating.vue"
export default component.exports