<template>
  <div class="gallery">
    <div class="gallery-item-wrap"
      v-for="(it, idx) in gallery"
      :key="it.s.url">
      <figure class="gallery-item">
        <img
          class="gallery--img"
          v-lazy="{ src: it.m.url, error: it.s.url, loading: idx < 6 ? it.s.url : undefined }"
        />
      </figure>
    </div>
  </div>
  <!-- <div class="gallery">
    <SwiperContainer
      :swiperConfig="swiperConfig"
      ref="swiper"
      v-model="swiperProps">
      <template slot-scope="{ swiper }">
        <SwiperSlides
          :idx="idx"
          :swiper="swiper"
          v-for="(it, idx) in gallery"
          :key="it.s.fileName"
          >
          <div class="gallery-item-wrap">
            <figure class="gallery-item">
              <img
                class="gallery--img"
                v-lazy="{ src: it.m.url, error: it.s.url, loading: idx < 6 ? it.s.url : undefined }"
              />
            </figure>
          </div>
        </SwiperSlides>
      </template>
    </SwiperContainer>
  </div> -->
</template>

<script>
export default {
  name: 'Gallery',
  // components: {
  //   'SwiperContainer': () => import(/* webpackChunkName: "mobiledefer" */'./swiper/SwiperContainer'),
  //   'SwiperSlides': () => import(/* webpackChunkName: "mobiledefer" */'./swiper/SwiperSlides')
  // },
  props: {
    gallery: {
      type: Array,
      default () {
        return []
      },
      required: true
    }
  },
  data () {
    return {
      swiperProps: {},
      swiperConfig: {
        slidesPerView: 'auto',
        spaceBetween: 0
      }
    }
  },
  mounted () {
    // this.$Lazyload.$on('loaded', this.handleImageLoaded)
  },
  beforeDestroy () {
    // this.$Lazyload.$off('loaded', this.handleImageLoaded)
  },
  methods: {
    // Listen to the image lazy load. and update swiper gallery
    // handleImageLoaded ({ bindType, el, naturalHeight, naturalWidth, $parent, src, loading, error }) {
    //   this.$refs.swiper.swiper.update()
    // }
  }
}
</script>

<style lang="scss" scoped>
  @import '../styles/bulma-variables';

  .gallery {
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    transform: perspective(0px);
  }

  .gallery-item-wrap {
    flex-shrink: 0;
    width: auto !important;
    // width: calc(100% - #{$bleed * 2});
    // height: calc(100% - #{$bleed * 2});
    height: 100%;
    min-width: 180px;
    .gallery--fullscreen & {
      width: calc(90vw - #{$bleed * 4}) !important;
      height: calc(90vh - #{$bleed * 4}) !important;
      display: flex;
      align-items: center;
    }
    // height: 300px + ($bleed * 2);
    padding: $bleed;
    padding-right: 0;
  }

  .gallery-item {
    box-shadow: inset 0 0 40px 5px rgba(10, 10, 10, 0.1);
    border-radius: $bleed;
    height: inherit;
    .gallery--fullscreen & {
      height: auto;
    }
  }

  .gallery--img {
    border-radius: $bleed;
    min-width: 200px;
    // width: 100%;
    height: 100%;
    display: block;
    opacity: 0;
    transition: opacity 80ms ease-in 300ms;
    .gallery--fullscreen & {
      height: auto;
      max-height: 100%;
    }
    &[lazy=loading] {
      opacity: 0
    }
    &[lazy=loaded],
    &[lazy=error] {
      opacity: 1;
    }
  }
</style>
