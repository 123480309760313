import factories from '../factories'

export default {
  data () {
    return {
      /** might overwriten  */
      fetchCompleted: true,
      maxFetchCount: 3,
      fetchInterval: 1000, // ms
      /** below is private data */
      mx_searchOne_isSearching: false,
      mx_searchOne_continuePolling: false,
      mx_searchOne_property: null,
      mx_searchOne_reviews: null,
      mx_searchOne_availability: null,
      mx_searchOne_iTimer: undefined,
      mx_searchOne_iSearchId: undefined,
      mx_searchOne_iFetchCount: 0,
      mx_searchOne_iDelta: Math.PI
    }
  },
  computed: {
    mx_searchOne_waitFor () {
      /**
       * Gradunily increase the interval time
       */
      return this.mx_searchOne_iDelta / this.mx_searchOne_iFetchCount * this.fetchInterval
    },
    mx_searchOne_isLoading () {
      return this.mx_searchOne_isSearching || !this.fetchCompleted
    }
  },
  beforeDestroy () {
    // this.mx_searchOne_cancelFetch()
  },
  deactived () {
    // this.mx_searchOne_cancelFetch()
  },
  methods: {
    /** non prefix might overwriten */
    fetchCompletedCallback () {

    },
    mx_searchOne_doCountFetch (searchId) {
      if (searchId === this.mx_searchOne_iSearchId) {
        this.mx_searchOne_iFetchCount++
        return
      }
      this.mx_searchOne_iSearchId = searchId
      this.mx_searchOne_iFetchCount = 1
    },
    mx_searchOne_fetch () {
      if (this.query == null || this.params == null) throw new Error('Missig required query & params')
      if (this.query.searchId == null) {
        console.warn('Search without searchId')
        return this.$router.replace({ name: 'property', params: this.params, query: { ...this.query, searchId: this.$lv2_newSearchId() } }, () => {
          setTimeout(() => this.mx_searchOne_fetch(), 200)
        })
      }
      this.fetchCompleted = false
      this.$nextTick(() => {
        this.mx_searchOne_doCountFetch(this.query.searchId)
        factories.one(this.query, this.params)
          .then(res => this.mx_searchOne_setLocalData(res))
          .then(() => {
            if (this.mx_searchOne_continuePolling && this.maxFetchCount > this.mx_searchOne_iFetchCount) {
              this.mx_searchOne_iTimer = setTimeout(() => this.mx_searchOne_fetch(), this.mx_searchOne_waitFor)
              return
            }
            this.$emit('fetch-completed')
            this.fetchCompleted = true
            this.fetchCompletedCallback()
          })
      })
    },
    mx_searchOne_setLocalData (data = {}) {
      this.mx_searchOne_isSearching = data.isSearching
      this.mx_searchOne_continuePolling = data.continuePolling
      this.mx_searchOne_property = data.property
      this.mx_searchOne_reviews = data.reviews
      this.mx_searchOne_availability = data.availability
    },
    mx_searchOne_cancelFetch () {
      clearTimeout(this.mx_searchOne_iTimer)
      factories.source.one.cancel('SEARCH_ONE_MIXINS_BEFORE_DESTORY')
    }
  }
}
