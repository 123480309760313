import pckg from '../package.json'
import modal from './packages/modal'
import overlay from './packages/overlay'
import gap from './packages/gap'
import loading from './packages/loading'
import card from './packages/card'
import swipe from './packages/swipe'
import breadcrumb from './packages/breadcrumb'
import historyLink from './packages/history-link'

const components = [
  modal,
  overlay,
  gap,
  loading,
  card,
  swipe,
  historyLink,
  breadcrumb
]

const install = function (Vue, opts = {}) {
  components.forEach(component => {
    Vue.component(component.name, component)
  })
}

export const k = {
  version: pckg.version,
  install,
  modal,
  overlay,
  gap,
  loading,
  card,
  swipe,
  historyLink,
  breadcrumb
}

export default k
