<template>
  <article class="message is-info">
    <div class="message-header">
      <span><i class="el-icon-warning" /> Price change occured</span>
    </div>
    <div class="message-body" style="padding: 0;">
      <table class="price-change-table table is-fullwidth">
        <thead>
          <tr>
            <th class="col-left"><abbr title="Requested Room">Requested</abbr></th>
            <th class="col"></th>
            <th class="col-right has-text-weight-semibold highlight"><abbr title="Available Room">Available</abbr></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{ selectedPckg.supplierDescription }}</td>
            <td rowspan="3" class="has-text-centered" style="vertical-align: middle;">
              <i class="el-icon-d-arrow-right is-size-3 has-text-primary" />
            </td>
            <td class="highlight"><span class="has-text-weight-semibold">{{ supplierDescription }}</span></td>
          </tr>
          <tr>
            <td>{{ selectedPckg.foodCode | foodCode }}</td>
            <td class="highlight"><span class="has-text-weight-semibold">{{ foodCode | foodCode }}</span></td>
          </tr>
          <tr>
            <td>{{ selectedPckg.unitAdjustedDisplayRate | currencyObj }}</td>
            <td class="highlight"><span class="has-text-weight-semibold">{{ unitAdjustedDisplayRate | currencyObj }}</span></td>
          </tr>
        </tbody>
      </table>
      <div v-if="hotelFees">Due at property {{ hotelFees }}</div>
    </div>
  </article>
</template>

<script>
export default {
  name: 'BookingPolicyPriceChange',
  props: [
    'taxAndFees',
    'adjustedDisplayRate',
    'unitAdjustedDisplayRate',
    'cancellationPolicy',
    'supplierDescription',
    'foodCode',
    'hotelFees',
    'selectedPckg'
  ],
  computed: {
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
  @import '../styles/bulma-variables';
  .price-change-table {
    color: $text;
    border: 2px solid $info;
    font-size: 0.875rem;
    background-color: transparent;
    th {
      font-size: $size-7;
      font-weight: 400;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
      abbr {
        color: $text;
        text-decoration: none !important;
      }
    }
    td {
      border-bottom-width: 0;
    }
    .highlight {
      color: $text-strong;
      // background-color: hsl(hue($info), saturation($info), 60%);
      background-color: hsla(hue($info), saturation($info), 90%, 1);
      // box-shadow: inset 0 0 30px hsla(hue($link), saturation($link), 92%, 0.9);
    }
    .col-left,
    .col-right {
      width: 46%;
    }
  }
</style>
