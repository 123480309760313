<template>
  <div :class="['pp-overlay', bgColorCssClass, fullScreenCssClass]"
    :style="[{ zIndex: zIndex }]"></div>
</template>

<script>
export default {
  name: 'PpOverlay',
  props: {
    isFullScreen: {
      type: Boolean,
      default: true
    },
    // Custom css class to overwrite
    bgColorCssClass: {
      type: String,
      default: ''
    },
    zIndex: Number
  },
  computed: {
    fullScreenCssClass () {
      return this.isFullScreen ? 'pp-overlay--fullscreen' : ''
    }
  }
}
</script>
