<template>
  <div class="pp-card">
    <header
      v-if="$slots.head"
      class="pp-card--head">
      <slot name="head"></slot>
    </header>
    <section
      class="pp-card--content">
      <slot></slot>
    </section>
    <footer
      v-if="$slots.foot"
      class="pp-card--foot">
      <slot name="foot"></slot>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'PpCard'
}
</script>
